import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
import { Badge } from '@ionic-native/badge/ngx';
// import { Deeplinks } from "@ionic-native/deeplinks/ngx";
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonMenu, Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { DEFAULT_ASSISTANT_EMAIL } from '../environments/environment';
import { UI_ROUTES } from './v2/constants/routes.constant';
import { PlatformUtilService } from './v2/services/utils/platform.util.service';
import { AssistantsToClientsService } from './_services/assistants-to-clients.service';
import { AuthService } from './_services/auth.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
const NO_REDIRECTED_ROUTES = ['/assistant/register', '/register', '/onboarding-slides', '/recovery-pass', '/recovery-pass/*'];

declare let cordova: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('mainMenu', { static: false }) mainMenu: IonMenu;

  navigate: any;
  isAssistant = false;
  isAuth = false;
  userInfo: any = {};
  userInfoSubscription: any;
  assigneesSubscription: any;
  deviceTokenSubscription: any;
  assignees: string[] = [];
  defaultAssignee = '';
  isDesktop = false;
  isSuperAdmin = false;
  menuFirstLoadAlreadyWas = false;
  isPayed = false;
  deviceToken = '';
  isCordovaIOS = this.platform.is('cordova') && this.platform.is('ios');

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authService: AuthService,
    private assistantToClientService: AssistantsToClientsService,
    private storage: Storage,
    private router: Router,
    private badge: Badge,
    private firebase: FirebaseX,
    private deeplinks: Deeplinks,
    private ngZone: NgZone,
    private platformUtilService: PlatformUtilService,
    private inAppBrowser: InAppBrowser
  ) {
    this.initializeApp();
  }

  ngOnInit() {}

  addGtmEvent(gtmTag) {
    if (window) {
      (window as any).dataLayer = (window as any).dataLayer || [];
      (window as any).dataLayer.push(gtmTag);
    }
  }

  // works for logout/login
  // userWasChanged$() {
  //   return this.authService.userInfo.pipe(
  //     distinctUntilChanged((prev: User, curr: User): any => {
  //       console.log('inside distinct ***');
  //       console.log(prev);
  //       console.log(curr);
  //       // if (!(prev === null && curr.id)) {
  //       //   return true;
  //       // } else {
  //       //   return false;
  //       // }
  //       return !curr || prev.id === curr.id;
  //       // return prev.id === curr.id;
  //     })
  //   )
  // }

  redirectIfNeeded() {
    console.log('AppComponent.redirectIfNeeded entry point ***');

    if (window.location.pathname.match(/^\/$/g)) {
      console.log('AppComponent.redirectIfNeeded redirects needed ***');

      if (this.isAssistant) {
        console.log('AppComponent.redirectIfNeeded isAssistant ***');
        this.router.navigateByUrl('/assistant/home');
      } else {
        console.log('AppComponent.redirectIfNeeded not assistant ***');

        if (this.platformUtilService.isDesktop()) {
          this.router.navigateByUrl(`/${UI_ROUTES.chat}`);
        } else {
          this.router.navigateByUrl(`/${UI_ROUTES.chat_mobile}`);
        }
      }
    }
  }

  loadAppData() {
    if (this.isAssistant) {
      // this.taskManager
      //   .fetchTasksByAssignee(this.userInfo.id)
      //   .subscribe((res: any[]) => {
      //     this.taskManager.loadTasks(res);
      //   });
    } else {
      this.assigneesSubscription = this.assistantToClientService.fetchAndPopulateAssignees$(this.userInfo.id).subscribe((res) => {
        if (res.length) {
          this.assignees = res;
          this.defaultAssignee = this.assignees[0];
        }
      });

      // this.taskManager
      //   .fetchTasksByCreator(this.userInfo.id)
      //   .subscribe((res: any[]) => {
      //     this.taskManager.loadTasks(res);
      //   });
    }
  }

  ngOnDestroy() {
    console.log('AppComponent.ngOnDestroy');

    this.userInfoSubscription.unsubscribe();
    this.assigneesSubscription.unsubscribe();
    if (this.deviceTokenSubscription) {
      this.deviceTokenSubscription.unsubscribe();
    }
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      console.log('AppComponent.initializeApp - Platform initialized');

      // Set statusBar and hide splashScreen
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      // Default add GTM Script for Android and Web platform
      if (!this.isCordovaIOS) {
        this.addGTMScript();
      }

      // If mobile cordova platform
      if (this.platform.is('cordova')) {
        if (this.platform.is('android')) {
          // Set instabug
          this.initializeInstabug();

          this.reqPermissionPN();
        } else {
          this.checkiOSIDFA();
        }

        this.initializePNListeners();

        this.deeplinks
          .route({
            '/recovery-pass/:token': 'recovery-pass',
          })
          .subscribe(
            (match) => {
              this.ngZone.run(() => {
                console.log('DEEPLINKS: ', match);
                const token = match.$args.token;
                this.router.navigateByUrl(`/recovery-pass/${token}`);
              });
            },
            (nomatch) => {
              // nomatch.$link - the full link data
              console.error("Got a deeplink that didn't match", nomatch);
            }
          );

        // Platform resume event listener
        this.platform.resume.subscribe(async () => {
          this.firebase.clearAllNotifications();
        });
      }

      // tslint:disable-next-line: triple-equals
      if (this.userInfo.Onboarding == true) {
        this.isPayed = true;
      } else {
        this.isPayed = false;
      }

      this.isDesktop = this.platform.is('desktop');
      const userId = await this.storage.get('USER_ID');

      console.log('AppComponent.initializeApp show user id: ', userId);

      this.authService.userAuthState$().subscribe((state) => {
        if (state === true) {
          console.log('AppComponent.ngOnInit user is authorized ***');

          this.isAuth = true;
          this.userInfoSubscription = this.authService.userInfo.subscribe((userInfo) => {
            console.log('AppComponent.ngOnInit userInfo was changed (this.authService.userInfo.subscribe) ***');

            // console.log('AppComponent.ngOnInit user is authorized ***');

            this.userInfo = userInfo;
            if (this.userInfo) {
              localStorage.setItem('customerID', this.userInfo.CustomerID);
            }

            if (this.platform.is('cordova')) {
              console.log('matched cordova platfrom ****');
              this.deviceTokenSubscription = this.authService.deviceToken.subscribe((token) => {
                console.log('Device Token Changed ***:');
                console.log(token);
                // if (token && (token !== this.userInfo.deviceToken)) {
                if (token) {
                  this.authService.registerDeviceToken(this.userInfo.id, token).subscribe((res) => {
                    console.log('Register Token Result: ***');
                    console.log(res);
                  });
                }
              });
            }

            if (this.userInfo) {
              this.isSuperAdmin = this.userInfo.email === DEFAULT_ASSISTANT_EMAIL;
              this.isAssistant = this.userInfo.isAssistant;
              // this.loadAppData();
              this.initializeMenu();
              this.redirectIfNeeded();
            } else {
              // for logout state update case
              this.isSuperAdmin = false;
              this.isAssistant = false;
            }
          });

          // works for login/logout and on reload when user is signed in
          this.authService.userWasChanged$().subscribe(() => {
            console.log('AppComponent.ngOnInit user was changed (this.authService.userInfo.subscribe) ***');

            this.isSuperAdmin = this.userInfo.email === DEFAULT_ASSISTANT_EMAIL;
            this.isAssistant = this.userInfo.isAssistant;
            this.loadAppData();
            this.initializeMenu();
            this.redirectIfNeeded();
          });
          // this.isAssistant = this.userInfo.isAssistant;
          // this.loadAppData();
          // this.initializeMenu();
          // this.redirectIfNeeded();
        }
      });

      const locationPathname = window.location.pathname.split('/');
      const hasNoRedirect = NO_REDIRECTED_ROUTES.filter((url) => {
        const parts = url.split('/');
        if (parts.length === locationPathname.length) {
          let check = 0;
          for (let i = 0; i < parts.length; i++) {
            if (parts[i] === locationPathname[i]) {
              check++;
            } else if (parts[i] === '*') {
              check++;
            }
          }

          return check === parts.length;
        } else {
          return false;
        }
      });
      if (hasNoRedirect.length >= 1 && !userId) {
        console.log('AppComponent.initializeApp - Prevent NO_REDIRECTED_ROUTES');
        return;
      }

      console.log('AppComponent.initializeApp  - Pass NO_REDIRECTED_ROUTES');

      this.router.events.forEach((item) => {
        if (item instanceof NavigationEnd) {
          const gtmTag = {
            event: 'page',
            pageName: item.url,
          };
          if (this.platform.is('ios') && this.platform.is('cordova')) {
            cordova.plugins.idfa.getInfo().then((info) => {
              // The app is closed if the user goes to the device settings and change the permission, so
              // tslint:disable-next-line: max-line-length
              // the following check should not be necessary. Leaving it, just in case there is another way to set that permission to false.
              if (info.trackingPermission === cordova.plugins.idfa.TRACKING_PERMISSION_AUTHORIZED) {
                this.addGtmEvent(gtmTag);
              }
            });
          } else {
            this.addGtmEvent(gtmTag);
          }
        }
      });

      // If mobile cordova platform
      if (this.platform.is('cordova')) {
        const slidesComplete = Boolean(await this.storage.get('slidesComplete'));
        const hasShowNotificationAlertPage = Boolean(await this.storage.get('hasShowNotificationAlertPage'));

        const hasPNPermission = await this.firebase.hasPermission();

        // Check if user has permission on notifications and flag to show page "notification-alert"
        if (!hasPNPermission && !hasShowNotificationAlertPage) {
          this.router.navigateByUrl('/notification-alert');
          return;
        } else {
          if (!slidesComplete) {
            console.log('Show onboarding Slides');
            this.router.navigateByUrl('/onboarding-slides');
            return;
          } else {
            if (this.platform.is('ios')) {
              // Set instabug
              this.initializeInstabug();
            }
          }
        }
      }

      if (userId) {
        this.authService.fetchUserInfo(userId).subscribe((res: any) => {
          console.log('AppComponent.initializeApp - fetchUserInfo by userId res:', res);
          // tslint:disable-next-line: triple-equals
          if (res.status == 404) {
            this.router.navigateByUrl('/login');
          }
        });
      } else {
        this.router.navigateByUrl('/login');
      }
    });
  }

  private checkiOSIDFA() {
    this.authService.initIDFA.subscribe((r) => {
      console.log('initIDFA--->', r);
      if (r !== 'null') {
        if (r === 'ok') {
          this.addGTMScript();
        } else {
          this.addGtmEvent({ 'gtm.start': new Date().getTime(), event: 'gtm.js', 'gtm.uniqueEventId': 1 });
          this.addGtmEvent({ event: 'gtm.dom', 'gtm.uniqueEventId': 2 });
          this.addGtmEvent({ event: 'gtm.load', 'gtm.uniqueEventId': 3 });
        }
      }
    });
  }

  addGTMScript() {
    // If tracking is Authorized, add the tracking script to the html.
    const script = document.createElement('script');
    script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-TX84Z7P');`;
    const noScript = document.createElement('noscript');
    const iframe = document.createElement('iframe');
    iframe.src = 'https://www.googletagmanager.com/ns.html?id=GTM-TX84Z7P';
    iframe.width = '0';
    iframe.height = '0';
    iframe.style.display = 'none';
    iframe.style.visibility = 'hidden';
    noScript.appendChild(iframe);
    document.head.appendChild(script);
    document.body.appendChild(noScript);
  }

  initializeMenu() {
    let clientNav = this.createClientNav();
    if (this.userInfo.sopURL) {
      clientNav = this.insertProcessDocumentOption(clientNav);
    }
    console.log('info del usuario', this.userInfo);
    // console.log("userInfo.Onboarding",this.userInfo.Onboarding);
    // if (this.userInfo.Onboarding==1) {
    //   clientNav.splice(3, 1);
    // } else {
    //   // clientNav.splice(5, 1);
    // }

    const superAdminNav = [
      {
        icon: 'menu-chat.png',
        title: 'Home',
        url: '/assistant/home',
      },
      {
        icon: 'menu-profile.png',
        title: ' Profile Settings',
        url: '/assistant/profile',
      },
      {
        icon: 'menu-profile.png',
        title: 'Admin Panel',
        url: '/admin-panel',
      },
      // {
      //   title: "Check Analytics",
      //   clickHandler: () => {
      //     (<any>window).gtag_report_conversion("check_analytics");
      //     // window.gtag_report_conversion('check_analytics');
      //   },
      // },
      {
        icon: 'menu-logout.png',
        title: 'Log Out',
        clickHandler: async () => {
          await this.authService.logout();
          if (this.mainMenu.isOpen) {
            this.mainMenu.close();
          }
          this.router.navigateByUrl('/login');
        },
      },
    ];

    const assistantNav = [
      {
        icon: 'menu-chat.png',
        title: 'Home',
        url: '/assistant/home',
      },
      {
        icon: 'menu-profile.png',
        title: ' Profile Settings',
        url: '/assistant/profile',
      },
      {
        icon: 'menu-process-document.png',
        title: 'Reference Guide',
        url: '/assistant/reference',
      },
      {
        title: 'Meet with Assistant Success',
        url: 'assistant/calendar-book',
      },
      {
        title: "My To Do's",
        icon: 'menu-chat.png',
        url: this.platformUtilService.isDesktop() ? 'assistant/todos' : `/${UI_ROUTES.monday_mobile}`,
      },
      {
        icon: 'menu-logout.png',
        title: 'Log Out',
        clickHandler: async () => {
          await this.authService.logout();
          if (this.mainMenu.isOpen) {
            this.mainMenu.close();
          }
          this.router.navigateByUrl('/login');
        },
      },
    ];
    // console.log('Is assitant:');
    // console.log(this.isAssistant);
    this.navigate = this.isSuperAdmin ? superAdminNav : this.isAssistant ? assistantNav : clientNav;
  }

  insertProcessDocumentOption(nav: any[]) {
    const arr1 = nav.slice(0, 2);
    const arr2 = [
      {
        icon: 'menu-process-document.png',
        title: 'Process Document',
        url: '/process-doc',
      },
    ];
    const arr3 = nav.slice(2);
    return arr1.concat(arr2, arr3);
  }

  private initializeInstabug() {
    (<any>window).InstabugLib.Instabug.start(
      '4bfb5359a6a2c62df6f0982f42548311',
      () => {
        console.log('Instabug initialized.');
      },
      (error: any) => {
        console.log('Instabug could not be initialized - ' + error);
      }
    );
    (<any>window).InstabugLib.Instabug.setColorTheme('dark');
  }

  private reqPermissionPN() {
    this.firebase.grantPermission().then(
      (ok) => {
        console.log('FIREBASE - grantPermission - OK: ', ok);
      },
      (err) => {
        console.error('FIREBASE - grantPermission - ERR: ', err);
      }
    );
  }

  private initializePNListeners() {
    console.log('initializePNListeners ****');

    this.firebase.getToken().then((token) => {
      console.log('FIREBASE - getToken:', token);
      this.authService.setDeviceToken(token);
    });

    this.firebase.onTokenRefresh().subscribe((token) => {
      console.log('FIREBASE - onTokenRefresh:', token);
      this.authService.setDeviceToken(token);
    });

    this.firebase.onMessageReceived().subscribe((data) => {
      console.log('FIREBASE - onMessageReceived - DATA: ', data);
      console.log('FIREBASE - onMessageReceived -  BADGE: ', this.badge);
      // this.badge.increase(1);
      if (data.tap) {
        console.log('FIREBASE - tap: ' + data.tap);
        // this.badge.clear();
        if (data.roomId) {
          if (this.platformUtilService.isDesktop()) {
            this.router.navigateByUrl(`/${UI_ROUTES.chat}/${data.roomId}`);
          } else {
            this.router.navigateByUrl(`/${UI_ROUTES.chat_mobile}/${data.roomId}`);
          }
        }
      } else {
        // console.log('Received in foreground ***');
      }
    });
  }

  createClientNav() {
    const paymentOptionUp = Number(this.userInfo.Onboarding)
      ? [
          {
            icon: 'menu-chat.png',
            title: 'Chat',
            url: this.platformUtilService.isDesktop() ? `/${UI_ROUTES.chat}` : `/${UI_ROUTES.chat_mobile}`,
          },
          {
            icon: 'menu-time-tracker.png',
            title: 'Time Tracker',
            url: '/time-tracker',
          },
        ]
      : [];

      paymentOptionUp.push({
        icon: 'menu-task-manager.png',
        title: 'Task Manager',
            url: this.platformUtilService.isDesktop() ? '/taskv2' : `/${UI_ROUTES.taskv2_mobile}`,
      });

    const paymentOptionDown = Number(this.userInfo.Onboarding)
      ? [
          {
            icon: 'menu-housekeeping.png',
            title: 'Housekeeping',
            url: '/housekeeping',
          },
        ]
      : [];
    return [
      ...paymentOptionUp,
      {
        icon: 'menu-payment.png',
        title: 'Payment',
        url: '/onboarding',
      },
      {
        icon: 'menu-schedule-call.png',
        title: 'Schedule a Call',
        url: '/schedule-call',
      },
      {
        icon: 'menu-profile.png',
        title: 'Profile Settings',
        url: '/profile',
      },
      ...paymentOptionDown,
      {
        icon: 'menu-refer-a-friend-icon.svg',
        title: 'Refer a friend',
        clickHandler: () => {
          if (this.platform.is('cordova') && this.platform.is('ios')) {
            this.inAppBrowser.create('https://chatterboss.getrewardful.com/signup', '_system');
          } else {
            window.open('https://chatterboss.getrewardful.com/signup', '_blank');
          }
        },
      },
      {
        icon: 'menu-logout.png',
        title: 'Log Out',
        clickHandler: async () => {
          await this.authService.logout();
          if (this.mainMenu.isOpen) {
            this.mainMenu.close();
          }
          this.router.navigateByUrl('/login');
          localStorage.removeItem('customerID');
        },
      },
    ];
  }

  async goTo(page: any) {
    if (page.clickHandler) {
      page.clickHandler();
    } else {
      this.router.navigateByUrl(page.url);
    }
  }
}
