import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "./auth.service";
import { ChatService } from "./chat.service";
import { environment, SERVER_URL } from "../../environments/environment";

import { map } from "rxjs/operators";
import { combineLatest } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AssistantsToClientsService {
  private SERVER_URL = SERVER_URL;
  isAssistant;

  private _assignees: BehaviorSubject<Array<string>> = new BehaviorSubject([]);

  public readonly assignees: Observable<
    Array<string>
  > = this._assignees.asObservable();

  constructor(
    private authService: AuthService,
    private chatService: ChatService,
    private httpClient: HttpClient
  ) {}

  assigneesData$(): any {
    return combineLatest(this._assignees, this.chatService.usersSubject).pipe(
      map(([assigneeIds, users]) => {
        return assigneeIds.map((id: string) =>
          users.find((userData) => {
            return userData.id === id;
          })
        );
      })
    );
  }

  fetchAndPopulateAssignees$(clientId) {
    return this.fetchAssignedToClient(clientId).pipe(
      tap((instances: Array<string>) => {
        this._assignees.next(instances);
      })
    );
  }

  fetchAssignedToClient(clientId) {
    return this.httpClient.get(`${SERVER_URL}/assignedToUser/${clientId}`);
  }

  assignAssitant$(assistantId, clientId) {
    return this.httpClient
      .post(`${SERVER_URL}/assignAssistant/${assistantId}`, {
        clientId: clientId,
      })
      .pipe(
        tap((res: any) => {
          this.addAssignee(assistantId);
        })
      );
  }

  unassignAssistant$(assistantId, clientId) {
    return this.httpClient
      .post(`${SERVER_URL}/unassignAssistant/${assistantId}`, {
        clientId: clientId,
      })
      .pipe(
        tap((res: any) => {
          this.deleteAssignee(assistantId);
        })
      );
  }

  loadAssignees(assignees: Array<string>) {
    this._assignees.next(assignees);
  }

  getAssignees() {
    return this._assignees.getValue();
  }

  addAssignee(assignee) {
    this._assignees.next([...this._assignees.getValue(), assignee]);
  }

  deleteAssignee(assignee) {
    let assignees = this._assignees.getValue();
    const assigneeIndex = assignees.findIndex(
      (currAssigneeId) => currAssigneeId == assignee
    );
    assignees.splice(assigneeIndex, 1);
    this._assignees.next(assignees);
  }
}
