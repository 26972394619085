import { AuthService } from '../_services/auth.service';
import { Router } from '@angular/router';
import { DEFAULT_ASSISTANT_EMAIL } from 'src/environments/environment';
import { Storage } from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { SERVER_URL } from "../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "../_services/auth.service";
import * as i2 from "@angular/router";
import * as i3 from "@ionic/storage";
import * as i4 from "@angular/common/http";
var OnboardingGuard = /** @class */ (function () {
    function OnboardingGuard(authService, router, storage, http) {
        this.authService = authService;
        this.router = router;
        this.storage = storage;
        this.http = http;
    }
    OnboardingGuard.prototype.canActivateChild = function (next, state) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.storage.get('USER_ID')
                .then(function (userId) {
                _this.http.get(SERVER_URL + "/user/" + userId).subscribe(function (resp) {
                    if (Number(resp.Onboarding) || resp.isAssistant || resp.email === DEFAULT_ASSISTANT_EMAIL) {
                        resolve(true);
                    }
                    else {
                        _this.router.navigateByUrl("/onboarding");
                        resolve(false);
                    }
                });
            });
        });
    };
    OnboardingGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OnboardingGuard_Factory() { return new OnboardingGuard(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.Storage), i0.ɵɵinject(i4.HttpClient)); }, token: OnboardingGuard, providedIn: "root" });
    return OnboardingGuard;
}());
export { OnboardingGuard };
