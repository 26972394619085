import { Injectable } from '@angular/core';
import { CanActivateChild , ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../_services/auth.service';
import { Router } from '@angular/router';
import { DEFAULT_ASSISTANT_EMAIL } from 'src/environments/environment';
import { Storage } from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { SERVER_URL } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class OnboardingGuard implements CanActivateChild {
  constructor(private authService: AuthService, private router: Router, private storage: Storage, private http: HttpClient ){}
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      return new Promise( (resolve) => {
        this.storage.get('USER_ID')
          .then(  userId => {
            this.http.get(
              `${SERVER_URL}/user/${userId}`
            ).subscribe( (resp: any) => {
                if( Number(resp.Onboarding) || resp.isAssistant || resp.email === DEFAULT_ASSISTANT_EMAIL ){
                  resolve(true);
                }else{
                  this.router.navigateByUrl(`/onboarding`);
                  resolve(false);
                }
              });
          })
        })
    ;  
  }
}