import { BehaviorSubject } from "rxjs";
import { tap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "./auth.service";
import { ChatService } from "./chat.service";
import { SERVER_URL } from "../../environments/environment";
import { map } from "rxjs/operators";
import { combineLatest } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
import * as i2 from "./chat.service";
import * as i3 from "@angular/common/http";
var AssistantsToClientsService = /** @class */ (function () {
    function AssistantsToClientsService(authService, chatService, httpClient) {
        this.authService = authService;
        this.chatService = chatService;
        this.httpClient = httpClient;
        this.SERVER_URL = SERVER_URL;
        this._assignees = new BehaviorSubject([]);
        this.assignees = this._assignees.asObservable();
    }
    AssistantsToClientsService.prototype.assigneesData$ = function () {
        return combineLatest(this._assignees, this.chatService.usersSubject).pipe(map(function (_a) {
            var assigneeIds = _a[0], users = _a[1];
            return assigneeIds.map(function (id) {
                return users.find(function (userData) {
                    return userData.id === id;
                });
            });
        }));
    };
    AssistantsToClientsService.prototype.fetchAndPopulateAssignees$ = function (clientId) {
        var _this = this;
        return this.fetchAssignedToClient(clientId).pipe(tap(function (instances) {
            _this._assignees.next(instances);
        }));
    };
    AssistantsToClientsService.prototype.fetchAssignedToClient = function (clientId) {
        return this.httpClient.get(SERVER_URL + "/assignedToUser/" + clientId);
    };
    AssistantsToClientsService.prototype.assignAssitant$ = function (assistantId, clientId) {
        var _this = this;
        return this.httpClient
            .post(SERVER_URL + "/assignAssistant/" + assistantId, {
            clientId: clientId,
        })
            .pipe(tap(function (res) {
            _this.addAssignee(assistantId);
        }));
    };
    AssistantsToClientsService.prototype.unassignAssistant$ = function (assistantId, clientId) {
        var _this = this;
        return this.httpClient
            .post(SERVER_URL + "/unassignAssistant/" + assistantId, {
            clientId: clientId,
        })
            .pipe(tap(function (res) {
            _this.deleteAssignee(assistantId);
        }));
    };
    AssistantsToClientsService.prototype.loadAssignees = function (assignees) {
        this._assignees.next(assignees);
    };
    AssistantsToClientsService.prototype.getAssignees = function () {
        return this._assignees.getValue();
    };
    AssistantsToClientsService.prototype.addAssignee = function (assignee) {
        this._assignees.next(this._assignees.getValue().concat([assignee]));
    };
    AssistantsToClientsService.prototype.deleteAssignee = function (assignee) {
        var assignees = this._assignees.getValue();
        var assigneeIndex = assignees.findIndex(function (currAssigneeId) { return currAssigneeId == assignee; });
        assignees.splice(assigneeIndex, 1);
        this._assignees.next(assignees);
    };
    AssistantsToClientsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AssistantsToClientsService_Factory() { return new AssistantsToClientsService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.ChatService), i0.ɵɵinject(i3.HttpClient)); }, token: AssistantsToClientsService, providedIn: "root" });
    return AssistantsToClientsService;
}());
export { AssistantsToClientsService };
