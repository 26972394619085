import * as tslib_1 from "tslib";
import * as Hammer from 'hammerjs';
import { HammerGestureConfig } from '@angular/platform-browser';
var MyHammerConfig = /** @class */ (function (_super) {
    tslib_1.__extends(MyHammerConfig, _super);
    function MyHammerConfig() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.overrides = {
            'swipe': { direction: Hammer.DIRECTION_ALL },
            'pan': { direction: Hammer.DIRECTION_ALL, threshold: 10 }
        };
        return _this;
    }
    return MyHammerConfig;
}(HammerGestureConfig));
export { MyHammerConfig };
