import { Platform } from '@ionic/angular';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
var PlatformUtilService = /** @class */ (function () {
    function PlatformUtilService(platform) {
        this.platform = platform;
    }
    PlatformUtilService.prototype.isDesktop = function () {
        return !this.isMobile();
    };
    PlatformUtilService.prototype.isMobile = function () {
        return this.platform.is('cordova');
    };
    PlatformUtilService.prototype.isIOS = function () {
        return this.platform.is('ios');
    };
    PlatformUtilService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PlatformUtilService_Factory() { return new PlatformUtilService(i0.ɵɵinject(i1.Platform)); }, token: PlatformUtilService, providedIn: "root" });
    return PlatformUtilService;
}());
export { PlatformUtilService };
