import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { tap } from "rxjs/operators";
import { Observable, BehaviorSubject } from "rxjs";
import { Storage } from "@ionic/storage";
import { User } from "../user";
import { environment, SERVER_URL } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class DBService {
  DB_SERVER: string = SERVER_URL;

  authState = new BehaviorSubject({
    users: {},
  });

  userInfo: User;

  constructor(private httpClient: HttpClient, private storage: Storage) {}

  getUsersById(userIds: Array<String>): Observable<any> {
    const reqUrl = `${this.DB_SERVER}/usersById/${JSON.stringify(userIds)}`;
    return this.httpClient.get(reqUrl, { observe: "response" });
  }

  async getUserData(userId: String): Promise<User> {
    const reqUrl = `${this.DB_SERVER}/user/${userId}`;
    return this.httpClient.get<User>(reqUrl).toPromise();
  }
 
  getAllUsers(): Observable<any> {
    return this.httpClient.get(`${SERVER_URL}/allUsers`);
  }

  getUsers(active,page?):Promise<any> {
    if(!page)
      return this.httpClient.get(`${SERVER_URL}/users?active=${active}`).toPromise();
    else{
      return this.httpClient.get(`${SERVER_URL}/users?active=${active}&page=${page}`).toPromise();
    }
  }

  getUsersAssistants(): Promise<any> {
      return this.httpClient.get(`${SERVER_URL}/users?assistants=true`).toPromise();
  }

  searchUsers(name): Observable<any>{
    return this.httpClient.post(`${SERVER_URL}/searchUsers`, {
      name: name,
    });
  }

  async updateUserData(userId, data) {
    return await this.httpClient.put(`${SERVER_URL}/user/${userId}`, data);
  }
  
  async deleteUser(userId) {
    return await this.httpClient.delete(`${SERVER_URL}/user/${userId}`);
  }
}
