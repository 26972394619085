import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FirebaseX } from "@ionic-native/firebase-x/ngx";
import { Platform } from "@ionic/angular";
import { Storage } from "@ionic/storage";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { distinctUntilChanged, map, tap } from "rxjs/operators";
import { DEFAULT_ASSISTANT_EMAIL, SERVER_URL } from "../../environments/environment";
import { User } from "./../user";
import { ChatService } from "./chat.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  AUTH_SERVER: string = SERVER_URL;

  authState = new BehaviorSubject(false);
  userInfo = new BehaviorSubject({} as User); 
  deviceToken = new BehaviorSubject<String>("");
  isUserSignedIn = new BehaviorSubject<Boolean>(false);

  initIDFA = new BehaviorSubject<'null' | 'ok' | 'false'>('null');

  constructor(
    private httpClient: HttpClient,
    private storage: Storage,
    private chatService: ChatService,
    private platform: Platform,
    private firebase: FirebaseX
  ) {
  }

  register(userInfo: User, isAssistant: Boolean = false): Observable<User> {
    userInfo.isAssistant = !!isAssistant;
    return this.httpClient.post<User>(`${this.AUTH_SERVER}/register`, userInfo);
  }

  checkForDuplicate(userInfo: User): Observable<Array<String>> {
    return this.httpClient.post<Array<String>>(
      `${this.AUTH_SERVER}/checkForDuplicate`,
      userInfo
    );
  }

  login(userInfo: User): Observable<any> {
    return this.httpClient.post(`${this.AUTH_SERVER}/login`, userInfo).pipe(
      tap(
        async (res: {
          status: number;
          access_token;
          expires_in;
          user_id;
          user_info;
        }) => {
          const userWasBanned = res && res.user_info && res.user_info.streamChatData && res.user_info.streamChatData.banned;
          const isPause = res && res.user_info && res.user_info.isPause;
          const toDelete = res && res.user_info && res.user_info.toDelete;
          if (isPause || toDelete) {
            return;
          }
          if (res.status !== 404 && !userWasBanned) {
            this.userInfo.next(res.user_info);
            await this.storage.set("ACCESS_TOKEN", res.access_token);
            await this.storage.set("EXPIRES_IN", res.expires_in);
            await this.storage.set("USER_INFO", res.user_info);
            await this.storage.set("USER_ID", res.user_id);
            await this.storage.set(
              "IS_USER_ASSISTANT",
              res.user_info.isAssistant
            );
            this.authState.next(true);
          }
        }
      )
    );
  }

  userAuthState$(): any {
    return combineLatest(this.authState, this.userWasChanged$()).pipe(
      map(([authState, userInfo]) => {
        return authState && !!userInfo.id;
      })
    );
  }

  userWasChanged$() {
    return this.userInfo.pipe(
      distinctUntilChanged((prev: User, curr: User): any => {
        return !curr || prev.id === curr.id;
      })
    );
  }

  fetchUserInfo(userId: string): any {
    const userInfoSubscription = this.httpClient.get(
      `${this.AUTH_SERVER}/user/${userId}`
    );
    userInfoSubscription.subscribe(async (res: any) => {
      const { status } = res;

      const isAuth = this.authState.getValue();
      if (status != 404) {
        this.userInfo.next(res);
        await this.storage.set("USER_INFO", res);
      }
      if (!isAuth) {
        this.authState.next(true);
      }
    });
    return userInfoSubscription;
  }

  getUserInfoById(userId: string) {
   return this.httpClient.get(`${this.AUTH_SERVER}/user/${userId}`);
  }

  isSuperAssistant() {
    return (
      this.authState &&
      this.userInfo.getValue().email === DEFAULT_ASSISTANT_EMAIL
    );
  }

  saveUserData(userData) {
    return this.httpClient.put(
      `${SERVER_URL}/user/${this.userInfo.getValue().id}`,
      userData
    );
  }

  enableAuthFlag() {
    this.authState.next(true);
  }

  isSuccessfullySignedIn() {
    return this.authState.getValue() && !!this.userInfo.getValue().id;
  }

  registerAssistant(userInfo: User): Observable<any> {
    return this.register(userInfo, true);
  }

  getUserInfo() {
    return this.userInfo.getValue();
  }

  async logout() {
    if (this.platform.is('cordova')) {
      this.firebase.clearAllNotifications();
      this.resetDeviceToken().subscribe();
    }

    await this.storage.remove("ACCESS_TOKEN");
    await this.storage.remove("EXPIRES_IN");
    await this.storage.remove("USER_INFO");
    await this.storage.remove("USER_ID");
    await this.storage.remove("IS_USER_ASSISTANT");

    this.userInfo.next(null);
    this.authState.next(false);
    this.chatService.disconnectFromStreamChat();
  }

  checkTokenExists(): Promise<boolean> {
    console.log("Checking token..");

    return new Promise((resolve) => {
      this.storage.get("ACCESS_TOKEN").then((token) => {
        if (token !== null) {
          console.log("Token exists, ", token);
          // this.authState.next(true);
          resolve(true);
        } else {
          console.log("No Token exists, ", token);
          // this.authState.next(false);
          resolve(false);
        }
      });
    });
  }

  setDeviceToken(token) {
    this.deviceToken.next(token);
  }

  getDeviceToken() {
    return this.deviceToken.getValue();
  }

  registerDeviceToken(userId, deviceToken) {
    return this.httpClient.post(`${SERVER_URL}/deviceToken`, {
      userId,
      token: deviceToken,
    });
  }

  resetDeviceToken() {
    // this method needs to be called before user info will be cleared

    const currentUserData = this.userInfo.getValue();

    if (!currentUserData) return;

    const { id: currentUserId } = currentUserData;

    return this.httpClient.post(`${SERVER_URL}/resetDeviceToken`, {
      userId: currentUserId,
    });
  }

  resetPassword(phone, newPassword) {
    return this.httpClient.post(`${this.AUTH_SERVER}/resetPassword`, {
      phone,
      newPassword,
    })
      .pipe(
        map((data: any) => {
          return data;
        })
      );
  }

  isLoggedIn() {
    return this.authState.value;
  }
}
