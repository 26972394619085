import * as tslib_1 from "tslib";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { Storage } from "@ionic/storage";
import { SERVER_URL } from "../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ionic/storage";
var DBService = /** @class */ (function () {
    function DBService(httpClient, storage) {
        this.httpClient = httpClient;
        this.storage = storage;
        this.DB_SERVER = SERVER_URL;
        this.authState = new BehaviorSubject({
            users: {},
        });
    }
    DBService.prototype.getUsersById = function (userIds) {
        var reqUrl = this.DB_SERVER + "/usersById/" + JSON.stringify(userIds);
        return this.httpClient.get(reqUrl, { observe: "response" });
    };
    DBService.prototype.getUserData = function (userId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var reqUrl;
            return tslib_1.__generator(this, function (_a) {
                reqUrl = this.DB_SERVER + "/user/" + userId;
                return [2 /*return*/, this.httpClient.get(reqUrl).toPromise()];
            });
        });
    };
    DBService.prototype.getAllUsers = function () {
        return this.httpClient.get(SERVER_URL + "/allUsers");
    };
    DBService.prototype.getUsers = function (active, page) {
        if (!page)
            return this.httpClient.get(SERVER_URL + "/users?active=" + active).toPromise();
        else {
            return this.httpClient.get(SERVER_URL + "/users?active=" + active + "&page=" + page).toPromise();
        }
    };
    DBService.prototype.getUsersAssistants = function () {
        return this.httpClient.get(SERVER_URL + "/users?assistants=true").toPromise();
    };
    DBService.prototype.searchUsers = function (name) {
        return this.httpClient.post(SERVER_URL + "/searchUsers", {
            name: name,
        });
    };
    DBService.prototype.updateUserData = function (userId, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.httpClient.put(SERVER_URL + "/user/" + userId, data)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    DBService.prototype.deleteUser = function (userId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.httpClient.delete(SERVER_URL + "/user/" + userId)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    DBService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DBService_Factory() { return new DBService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Storage)); }, token: DBService, providedIn: "root" });
    return DBService;
}());
export { DBService };
