import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormBuilder, FormsModule } from '@angular/forms';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
import { Badge } from '@ionic-native/badge/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { File } from '@ionic-native/file/ngx';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { Media } from '@ionic-native/media/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Stripe } from '@ionic-native/stripe/ngx';
import { VideoPlayer } from '@ionic-native/video-player/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { AngularFireModule } from 'angularfire2';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import {
  AngularFireFunctionsModule
} from 'angularfire2/functions';
import { NgCalendarModule } from 'ionic2-calendar';
import { NgxImageCompressService } from 'ngx-image-compress';
import { NgxPlaidLinkModule } from "ngx-plaid-link";
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { ConfirmEqualValidatorDirective } from './_helpers/confirm-equal-validator.directive';
import { MyHammerConfig } from './_helpers/hammer-config';
import { IonicGestureConfig } from './_helpers/ionic-gesture-config';
import { LoadingService } from './_services/loading.service';



@NgModule({
  declarations: [
    AppComponent,
    ConfirmEqualValidatorDirective,
    // MustMatchDirective
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    // TranslateModule,
    HttpClientModule,
    FormsModule,
    IonicStorageModule.forRoot(),
    NgCalendarModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    AngularFirestoreModule,
    NgxStripeModule.forRoot(environment.stripeKey),
    //GoogleTagManagerModule.forRoot({ id: 'GTM-TX84Z7P' }),
    ComponentsModule,
    NgxPlaidLinkModule
    // PdfViewerModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    Stripe,
    Camera,
    Deeplinks,
    File,
    WebView,
    FilePath,
    SocialSharing,
    InAppBrowser,
    VideoPlayer,
    FormBuilder,
    FirebaseX,
    Badge,
    LoadingService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Clipboard,
    NgxImageCompressService,
    Media,
    DatePipe,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: IonicGestureConfig
    },
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
    ScreenOrientation
  ],
  exports: [RouterModule],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule { }

