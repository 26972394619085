import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { DEFAULT_ASSISTANT_EMAIL, SERVER_URL, STREAM_KEY } from '../../environments/environment';
import { User } from './../user';
import { DBService } from './db.service';

import { StreamChat } from 'stream-chat';
import { UI_ROUTES } from '../v2/constants/routes.constant';
import { PlatformUtilService } from '../v2/services/utils/platform.util.service';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  // API_KEY = "fyawmqrej5fv";
  API_KEY = STREAM_KEY;

  presenceStore = {};

  usersSubject = new BehaviorSubject([]);
  roomsSubject = new BehaviorSubject([]);

  streamChatUsers = [];

  channelsSubject = new BehaviorSubject([]);

  messagesSubject = new BehaviorSubject([]);

  assistantsSubject = new BehaviorSubject([]);
  activeUsersSubject = new BehaviorSubject([]);
  activeChannelsSubject = new BehaviorSubject([]);
  lastPageFromDbSubject = new BehaviorSubject([]);

  streamChatClient: StreamChat = null;
  streamChatConnection: any = null;

  currentUserId = '';
  lastPageCount = 0;
  totalItems: -1;
  isSuperAssistant: boolean;

  constructor(
    private dbService: DBService,
    private httpClient: HttpClient,
    private storage: Storage,
    private router: Router,
    private platform: Platform,
    private platformUtilService: PlatformUtilService
  ) {
    this.setUpChatNotifications = this.setUpChatNotifications.bind(this);
  }

  enableWebPush(emitter) {
    if (!('Notification' in window)) {
      //console.log("This browser does not support desktop notification");
      return;
    }

    if (Notification.permission === 'granted') {
      // If it's okay let's create a notification

      emitter();
      // this.setUpChatNotifications();
    }

    // Otherwise, we need to ask the user for permission
    else if (Notification.permission !== 'denied') {
      if (navigator.userAgent.search('Safari') >= 0 && navigator.userAgent.search('Chrome') < 0) {
        Notification.requestPermission((permission) => {
          if (permission === 'granted') {
            emitter();
            // this.setUpChatNotifications();
          }
        });
      } else {
        Notification.requestPermission().then(function (permission) {
          // If the user accepts, let's create a notification

          if (permission === 'granted') {
            emitter();
            // this.setUpChatNotifications();
          }
        });
      }
    }
  }

  setUpChatNotifications() {
    this.streamChatClient.on('message.new', (event) => {
      const {
        user: { name: userName },
        message: { text: messageText },
        cid,
      } = event;

      const matches = cid.match(/^messaging\:(.*)$/);
      const channelId = matches[1] ? matches[1] : '';

      // to show notification only when tab is inactive
      if (document.hidden) {
        var notification = new Notification(userName, {
          body: messageText,
          data: {
            channelId: channelId,
          },
          tag: channelId,
        });

        notification.onclick = (event: any) => {
          const eventTarget = event.target && event.target;
          const notificationData = eventTarget && eventTarget.data;
          const channelId = notificationData ? notificationData.channelId : event.target.tag;
          // const channelId = data.target && data.target.data && data.target.data.channelId ? data.target.data.channelId : null;

          if (this.platformUtilService.isDesktop()) {
            this.router.navigateByUrl(`/${UI_ROUTES.chat}/${channelId}`);
          } else {
            this.router.navigateByUrl(`/${UI_ROUTES.chat_mobile}/${channelId}`);
          }

          window.focus();
        };
      }
    });
  }

  // StreamChat connection
  isConnectedToStreamChat() {
    return this.streamChatConnection !== null;
  }

  // obtine el access token y lo devuelve
  async getAccess() {
    const accessToken = await this.storage.get('ACCESS_TOKEN');
    return accessToken;
  }

  async connectToStreamChat(userId) {
    const accessToken = await this.storage.get('ACCESS_TOKEN');

    if (!accessToken) return false;

    this.streamChatClient = new StreamChat(this.API_KEY, {
      timeout: 10000,
    });

    this.streamChatConnection = await this.streamChatClient.setUser(
      {
        id: userId,
      },
      accessToken
    );

    this.currentUserId = userId;

    // check if app is running in desktop browser
    if (!this.platform.is('hybrid')) {
      // ask permission and subscribe to event
      this.enableWebPush(this.setUpChatNotifications);
    }

    /*************** code below should be moved to components **************** */

    // const filter = { type: 'messaging', members: { $in: [userId] } };
    // const sort = { last_message_at: -1 };

    // const channels = await this.streamChatClient.queryChannels(filter, sort, {
    //     watch: true,
    //     state: true,
    // });

    // //console.log('channels list: ***');
    // //console.log(channels);
    // for (const c of channels) {
    //     //console.log(c);
    //     const channelState = await c.watch();
    //     //console.log('channel state: ***');
    //     //console.log(channelState);
    // }

    // //console.log('current streamChat connection: ');
    // //console.log(this.streamChatConnection);

    /*************** code above should be moved to components **************** */
  }

  async disconnectFromStreamChat() {
    await this.streamChatClient.disconnect();
    this.streamChatClient = null;
    this.streamChatConnection = null;
    this.usersSubject.next([]);
    this.channelsSubject.next([]);
  }

  // Get data from Users
  async fetchAssistants() {
    await this.dbService.getUsersAssistants().then(async (assistants) => {
      this.assistantsSubject.next(assistants);
    });
  }

  fetchAndMergeUsersDataAll() {
    this.dbService.getAllUsers().subscribe(async (res) => {
      const usersFromDB = res;
      let mergedUsersData = [];

      const userIds = usersFromDB.map((userData) => userData.id);

      let restOfUsers: any = [];
      const limitForQuery = 50;

      for (let i = 0; i < userIds.length; i += limitForQuery) {
        const part = userIds.slice(i, i + limitForQuery);
        restOfUsers = await this.streamChatClient.queryUsers(
          {
            id: { $in: part },
          },
          { last_active: -1 },
          {
            presence: false,
            limit: limitForQuery,
          }
        );
        this.streamChatUsers = [...this.streamChatUsers, ...restOfUsers.users];
      }

      // TODO: streamChat returns only 100 users, but there are more. resolve it later. Maybe avatars will not be shown due to this
      usersFromDB.forEach((dbUserData) => {
        const streamChatUserData = this.streamChatUsers.find((streamChatUserData) => streamChatUserData.id === dbUserData.id);

        // when added this users without avatars dissapeared but a lot of users (who was without avatar dissapeared at all)
        if (!streamChatUserData) return;

        const mergedUserData = {
          ...dbUserData,
          ...streamChatUserData,
        };

        mergedUsersData.push(mergedUserData);
        // console.log('dbUserData: ***', dbUserData);
      });

      this.usersSubject.next(mergedUsersData);
      // console.log(this.usersSubject.getValue());
    });
  }

  async fetchAndMergeUsersData(active, page?) {
    if (active == 'true') {
      await this.dbService.getUsers(active, page).then(async (res) => {
        if (res.users.length <= 0) return;
        console.log('Active users:', res.users);
        this.lastPageFromDbSubject.next(res);
        this.totalItems = res.totalItems;
        this.fetchAndMergeUsersDataFromUsers(res.users, active);
      });
    } else {
      await this.dbService.getUsers(active, page).then(async (res) => {
        if (res.users.length <= 0) return;
        console.log(`Users page ${page}`, res.users);
        console.log(`Total items: ${res.totalItems}`);
        this.lastPageFromDbSubject.next(res);
        this.fetchAndMergeUsersDataFromUsers(res.users, active);
      });
    }
  }

  // async updateLastActivity() {
  //   this.dbService.getAllUsers()
  //     .subscribe( async(resp: any[]) => {
  //       const userIds = resp.map((userData) => userData.id).slice(1600);
  //       console.log( userIds.length );
  //       const limit = 30;
  //       let offset = 0;
  //       for (let index = 1; index <= userIds.length; index++) {

  //         const  part = userIds.slice(offset, limit * index);
  //         if(part.length === 0){
  //           continue;
  //         }
  //         const respQUser = await this.streamChatClient.queryUsers(
  //           {id: { $in: part } },
  //           { last_active: -1 },
  //           {});
  //         console.log( "la buena respuesta :",respQUser );
  //         respQUser.users.forEach(
  //           user => this.dbService.updateUserData( user.id, { lastActivity: user.last_active })
  //           .then( res => res.subscribe( console.log ) )
  //         )
  //         offset += limit;
  //       }
  //     });
  // }

  async fetchAndMergeUsersDataFromUsers(users, active) {
    const usersFromDB = users;
    let mergedUsersData = [];

    const userIds = usersFromDB.map((userData) => userData.id);

    this.streamChatUsers = [];
    let restOfUsers: any = [];
    const limitForQuery = 50;

    for (let i = 0; i < userIds.length; i += limitForQuery) {
      const part = userIds.slice(i, i + limitForQuery);
      restOfUsers = await this.streamChatClient.queryUsers(
        {
          id: { $in: part },
        },
        { last_active: -1 },
        {
          presence: false,
          limit: limitForQuery,
        }
      );
      this.streamChatUsers = [...this.streamChatUsers, ...restOfUsers.users];
    }
    // TODO: streamChat returns only 100 users, but there are more. resolve it later. Maybe avatars will not be shown due to this
    usersFromDB.forEach((dbUserData) => {
      const streamChatUserData = this.streamChatUsers.find((streamChatUserData) => streamChatUserData.id === dbUserData.id);

      // when added this users without avatars dissapeared but a lot of users (who was without avatar dissapeared at all)
      if (!streamChatUserData) return;

      const mergedUserData = {
        ...dbUserData,
        ...streamChatUserData,
      };

      mergedUsersData.push(mergedUserData);
      // console.log('dbUserData: ***', dbUserData);
    });

    if (active == 'true') {
      this.activeUsersSubject.next(mergedUsersData);
      const all = [...this.assistantsSubject.getValue(), ...mergedUsersData];
      this.usersSubject.next(all);
    } else {
      //const activeUsers = this.usersSubject.getValue();
      const all = [...this.assistantsSubject.getValue(), ...this.activeUsersSubject.getValue(), ...mergedUsersData];
      this.usersSubject.next(all);
    }
    console.log('merged users data ***', this.usersSubject.getValue());
  }

  searchUsers(value): Observable<any> {
    return new Observable((observer) => {
      return this.dbService.searchUsers(value).subscribe(async (users) => {
        this.fetchAndMergeUsersDataFromUsers(users, 'true');
        this.fetchStreamChatChannelsFromUsers(users, 'true');
        this.removeOnNewMessageHandler();
        observer.next(true);
      });
    });
  }

  updateAndMergeUsersData(ids: string[]) {
    console.warn(ids);
    return this.dbService.getUsersById(ids).pipe(
      flatMap(async (res) => {
        // console.warn(res);
        const usersFromDB = JSON.parse(res.body);
        let mergedUsersData = [];

        const userIds = usersFromDB.map((userData) => userData.id);

        let streamChatUsers = [];
        let restOfUsers: any = [];
        const limitForQuery = 100;
        let offsetForQuery = 0;

        do {
          restOfUsers = await this.streamChatClient.queryUsers(
            { id: { $in: userIds } },
            { last_active: -1 },
            {
              presence: false,
              limit: limitForQuery,
              offset: offsetForQuery,
            }
          );

          streamChatUsers = [...streamChatUsers, ...restOfUsers.users];
          offsetForQuery = offsetForQuery + restOfUsers.users.length; // this offset is calcaulated properly
        } while (restOfUsers.users.length >= limitForQuery);
        usersFromDB.forEach((dbUserData) => {
          const streamChatUserData = streamChatUsers.find((streamChatUserData) => streamChatUserData.id === dbUserData.id);

          // when added this users without avatars dissapeared but a lot of users (who was without avatar dissapeared at all)
          if (!streamChatUserData) return;

          const mergedUserData = {
            ...dbUserData,
            ...streamChatUserData,
          };

          mergedUsersData.push(mergedUserData);
        });
        // console.log("mergedUsersData ",mergedUsersData);
        return mergedUsersData;
      })
    );
  }

  async fetchAndMergeUsersDataAssistants(active) {
    const userIds = this.usersSubject.getValue().map((userData) => userData.id);
    if (userIds.length <= 0) return;

    this.dbService.getUsersById(userIds).subscribe((res) => {
      if (res.status === 200) {
        const data = JSON.parse(res.body);

        let mergedUsersData = [];

        data.forEach((userDataFromDB) => {
          // console.log("user data from db  $$ ***");
          // console.log(userDataFromDB);

          const userDataFromChat = this.usersSubject
            .getValue()
            .find((chatServiceData) => chatServiceData.id === userDataFromDB.id);

          // console.log("user data from chat $$ ***");
          // console.log(userDataFromChat);

          if (userDataFromChat) {
            mergedUsersData.push({
              ...userDataFromDB,
              ...userDataFromChat,
            });
          }
        });
        if (active) {
          this.activeUsersSubject.next(mergedUsersData);
          this.usersSubject.next(mergedUsersData);
        } else {
          const all = [...this.activeUsersSubject.getValue(), ...mergedUsersData];
          this.usersSubject.next(all);
        }
        console.log('usersSubject', this.usersSubject.getValue());
      }
    });
  }

  // Get data from Channels for Super Assistant
  async fetchStreamChatChannels(active) {
    let res: any = this.lastPageFromDbSubject.getValue();
    await this.fetchStreamChatChannelsFromUsers(res.users, active, res.totalItems);
  }

  async fetchStreamChatChannelsFromUsers(users: User[], active: string, totalItems?) {
    let channels2 = [];
    if (!users) return;
    if (users.length <= 0) return;

    const response = await this.getStreamChatClient(users);
    channels2 = response;
    console.log('respuesta active: ', active);
    if (active == 'true') {
      this.activeChannelsSubject.next(channels2);
      this.channelsSubject.next(channels2);
    } else {
      const all = [...this.activeChannelsSubject.getValue(), ...channels2];
      this.channelsSubject.next(all);
      if (totalItems) this.totalItems = totalItems;
    }
    console.log('channels in chat service after we got them ***', this.channelsSubject.getValue());
  }

  async getStreamChatClient(users: User[]) {
    const limit = 30;
    const userIds: string[] = users.map((userData) => userData.id);
    const index = userIds.indexOf(this.streamChatClient.user.id);
    if (index > -1) userIds.splice(index, 1);
    const filter = {
      type: 'messaging',
      members: { $in: [this.streamChatClient.user.id] } && { $in: userIds },
    };
    const sort = { last_message_at: -1 };
    let channels = [];
    for (let index = 0; index < userIds.length; index += limit) {
      const restOfChannels: any = await this.streamChatClient.queryChannels(filter, sort, {
        watch: true,
        state: true,
        limit,
        offset: index,
      });
      channels = [...channels, ...restOfChannels];
    }
    console.log('rest of chanel: ', channels);
    channels.map((channel) => ((channel.unreadCount = channel.countUnread()), (channel.unanswered = false)));
    return channels;
  }

  // Get data from Channels for Regular Assistants
  async fetchStreamChatChannelsAssistant(active) {
    let channels = [];
    let restOfChannels: any = [];
    const limitForQuery = 30;
    let offsetForQuery = 0;
    const assistants = this.assistantsSubject.getValue();

    let dateLimit = new Date();
    dateLimit.setHours(0);
    dateLimit.setMinutes(0);
    dateLimit.setSeconds(0);
    dateLimit.setDate(dateLimit.getDate() - 5);
    // dateLimit = new Date();
    // dateLimit.setMinutes(dateLimit.getMinutes() - 1);

    const filter = {
      type: 'messaging',
      members: { $in: [this.streamChatClient.user.id] },
    };
    const sort = { last_message_at: -1 };
    do {
      try {
        restOfChannels = await this.streamChatClient.queryChannels(filter, sort, {
          watch: true,
          state: true,
          limit: limitForQuery,
          offset: offsetForQuery,
        });
        restOfChannels.map((channel) => ((channel.unreadCount = channel.countUnread()), (channel.unanswered = false)));
        channels = [...channels, ...restOfChannels];
        offsetForQuery = offsetForQuery + restOfChannels.length;
      } catch (e) {
        console.error(e);
      }
    } while (restOfChannels.length >= limitForQuery);

    let channelsAux = [];
    channels.forEach((channel, index) => {
      let isActive = channel.state.last_message_at >= dateLimit;
      if (active == isActive) channelsAux.push(channel);
    });
    channels = channelsAux;
    // console.log("channels",channels);

    let allMembers = [];
    channels.forEach(async (channel, index) => {
      var roomUsers = channel.state.members.asMutable();
      Object.keys(roomUsers).forEach((userId) => {
        let userDataFromChatService = roomUsers[userId].user;

        var assistant = assistants.find((assistantData) => assistantData.id === userDataFromChatService.id);
        if (!assistant) allMembers.push(userDataFromChatService);
      });
    });
    // console.log("allMembers",allMembers);

    if (active) {
      this.activeUsersSubject.next(allMembers);
      this.usersSubject.next(allMembers);

      this.activeChannelsSubject.next(channels);
      this.channelsSubject.next(channels);
    } else {
      const allUsers = [...this.activeUsersSubject.getValue(), ...allMembers];
      this.usersSubject.next(allUsers);

      const allChannels = [...this.activeChannelsSubject.getValue(), ...channels];
      this.channelsSubject.next(allChannels);
    }
    console.log('channelsSubject', this.channelsSubject.getValue());
  }

  // Events
  attachChannelsUpdateOnNewMessageChannel() {
    this.streamChatClient.on('message.new', this.onNewMessageChannelEventHandler);
  }

  attachChannelsUpdateOnNewMessage() {
    this.streamChatClient.on('notification.message_new', this.onNewMessageClientEventHandler);
  }

  onNewMessageChannelEventHandler = (event) => {
    // console.log('new Channel message event ***',event);

    const currentChannelsSet = this.channelsSubject.getValue();

    const cid = event.cid;
    const matches = cid.match(/^messaging\:(.*)$/);
    const channelId = matches[1] ? matches[1] : '';

    const certainChannelIndex = currentChannelsSet.findIndex((channelData) => channelData.id === channelId);
    const certainChannel = currentChannelsSet[certainChannelIndex];

    if (!certainChannel) {
      const filter = {
        type: 'messaging',
        cid: { $in: [event.cid] },
        members: { $in: [this.streamChatClient.user.id] },
      };
      const sort = { last_message_at: -1 };

      this.streamChatClient
        .queryChannels(filter, sort, {
          watch: true,
          state: true,
          limit: 1,
        })
        .then((queryChannel) => {
          if (queryChannel.length > 0) {
            const channel = queryChannel[0];
            channel.queryMembers({}, { created_at: -1 }, {}).then((res) => {
              this.addUsersIfNotExists(res.members);
              this.addChannelFromEvent(event.cid);
            });
          }
        });
      return;
    }

    certainChannel.unreadCount = certainChannel.countUnread();
    if (this.isUserAssistant(event.user.id)) certainChannel.unanswered = false;
    // console.log("certain channel ***");
    // console.log(certainChannel);

    const newChannelsSet = [
      certainChannel,
      ...currentChannelsSet.slice(0, certainChannelIndex),
      ...currentChannelsSet.slice(certainChannelIndex + 1),
    ];

    this.channelsSubject.next(newChannelsSet);
  };

  onNewMessageClientEventHandler = (event) => {
    //console.log('new client message event ***',event);

    const currentChannelsSet = this.channelsSubject.getValue();

    //console.log('current channels set ***');
    //console.log(currentChannelsSet);

    const cid = event.cid;
    const matches = cid.match(/^messaging\:(.*)$/);
    const channelId = matches[1] ? matches[1] : '';

    const certainChannelIndex = currentChannelsSet.findIndex((channelData) => channelData.id === channelId);
    const certainChannel = currentChannelsSet[certainChannelIndex];

    if (!certainChannel) {
      this.addUsersIfNotExists(event.channel.members);
      this.addChannelFromEvent(event.channel.cid);
      return;
    }

    certainChannel.unreadCount = certainChannel.countUnread();

    // console.log("certain channel ***");
    // console.log(certainChannel);

    // console.log('certain channel index ***');
    // console.log(certainChannelIndex);

    const newChannelsSet = [
      certainChannel,
      ...currentChannelsSet.slice(0, certainChannelIndex),
      ...currentChannelsSet.slice(certainChannelIndex + 1),
    ];

    // console.log('new channels set ***');
    // console.log(newChannelsSet);

    this.channelsSubject.next(newChannelsSet);
  };

  removeOnNewMessageHandler() {
    this.streamChatClient.off('message.new', this.onNewMessageChannelEventHandler);
    this.streamChatClient.off('notification.message_new', this.onNewMessageClientEventHandler);
  }

  addChannelFromEvent(channelCid) {
    const currentChannelsSet = this.channelsSubject.getValue();
    const filter = {
      type: 'messaging',
      cid: { $in: [channelCid] },
    };
    const sort = { last_message_at: -1 };

    this.streamChatClient
      .queryChannels(filter, sort, {
        watch: true,
        state: true,
        limit: 1,
      })
      .then((queryChannel) => {
        // console.log("Current channels",currentChannelsSet);
        // console.log("Channel not loaded",event.channel);

        if (queryChannel.length > 0) {
          let newChannel = queryChannel[0];
          // console.log("New channel",newChannel);
          newChannel = Object.assign(newChannel, { unreadCount: queryChannel[0].countUnread(), unanswered: false });

          // console.log("New channel",newChannel);
          const newChannelsSet = [newChannel, ...currentChannelsSet];
          this.channelsSubject.next(newChannelsSet);
          // console.log("Channel added", this.channelsSubject.getValue());

          const newActiveChannels = [newChannel, ...this.activeChannelsSubject.getValue()];
          this.activeChannelsSubject.next(newActiveChannels);
        }
      });
  }

  addUsersIfNotExists(members) {
    if (!members) return;
    const userIds = members.map((userData) => userData.user_id);
    const currentUserIds = this.usersSubject.getValue().map((userData) => userData.id);
    let missingUserIds = userIds.filter((x) => !currentUserIds.includes(x));
    // console.log("missingUserIds ",missingUserIds);
    if (missingUserIds.length <= 0) return;

    this.updateAndMergeUsersData(missingUserIds).subscribe((mergedUsersData) => {
      const currentUsersSet = this.usersSubject.getValue();
      const all = [...currentUsersSet, ...mergedUsersData];
      // console.log("Current users:",this.usersSubject.getValue());
      // console.log("Missing users:",missingUserIds);
      this.usersSubject.next(all);
      // console.log("Users added:",this.usersSubject.getValue());

      const newActiveUsers = [...this.activeUsersSubject.getValue(), ...mergedUsersData];
      this.activeUsersSubject.next(newActiveUsers);
    });
  }

  updateChannelInSubject(cid) {
    const currentChannelsSet = this.channelsSubject.getValue();

    const matches = cid.match(/^messaging\:(.*)$/);
    const channelId = matches[1] ? matches[1] : '';

    const certainChannelIndex = currentChannelsSet.findIndex((channelData) => channelData.id === channelId);
    const certainChannel = currentChannelsSet[certainChannelIndex];

    certainChannel.unreadCount = certainChannel.countUnread();

    this.channelsSubject.next(currentChannelsSet);
  }

  attachChannelsUpdateOnMarkRead() {
    this.streamChatClient.on('message.read', (event) => {
      const isCurrentUserRead = event.user.id === this.currentUserId;

      if (!isCurrentUserRead) return;

      const currentChannelsSet = this.channelsSubject.getValue();

      const cid = event.cid;
      const matches = cid.match(/^messaging\:(.*)$/);
      const channelId = matches[1] ? matches[1] : '';

      const certainChannelIndex = currentChannelsSet.findIndex((channelData) => channelData.id === channelId);
      const certainChannel = currentChannelsSet[certainChannelIndex];

      certainChannel.unreadCount = certainChannel.countUnread();

      this.channelsSubject.next(currentChannelsSet);

      // //console.log('read message event for client was caught ***');
      // //console.log(event);
      // // //console.log(this.currentChannel.state.read);
      // //console.log('*********************************');
    });
  }

  attachChannelsUpdateOnAddedToChannel() {
    this.streamChatClient.on('notification.added_to_channel', async (event) => {
      const {
        // property id without prefix "messaging" exists there in correct format
        channel,
      } = event;
      // //console.log(`you were just added to channel ${event.channel}`);
      // //console.log('channel object you got from event');
      // //console.log(channel);

      const filter = { type: 'messaging', id: channel.id };
      const sort = { last_message_at: -1 };

      const channels = await this.streamChatClient.queryChannels(filter, sort, {
        watch: true,
        state: true,
      });

      const channelInstance = channels[0];

      const channelLastMessageAt = channelInstance.state.last_message_at;

      // //console.log('channelInstance: $$ ***');
      // //console.log(channelInstance);

      const channelState = await channelInstance.watch();

      const currentChannelsSet = this.channelsSubject.getValue();

      // //console.log('channels set before update: ***');
      // //console.log(currentChannelsSet);

      const firstLastMessageLaterMatch = currentChannelsSet.findIndex((channel) => {
        return channel.state.last_message_at > channelLastMessageAt;
      });

      // //console.log('firstLastMessageLaterMatch: $$ ***');
      // //console.log(firstLastMessageLaterMatch);

      let newChannelsSet;

      if (firstLastMessageLaterMatch === -1 || firstLastMessageLaterMatch === 0) {
        newChannelsSet = [channelInstance, ...currentChannelsSet];
      } else {
        newChannelsSet = [
          ...currentChannelsSet.slice(0, firstLastMessageLaterMatch),
          channelInstance,
          ...currentChannelsSet.slice(firstLastMessageLaterMatch + 1),
        ];
      }

      // Show notification on client register
      let date = new Date();
      date.setMinutes(date.getMinutes() - 5);
      let created_at = new Date(channel.created_at);

      if (created_at > date) {
        let userName = channel.name;
        let messageText = `A new client ${userName} just registered`;
        let channelId = channel.id;
        var notification = new Notification('ChatterBoss', {
          body: messageText,
          data: {
            channelId: channelId,
          },
          tag: channelId,
        });

        notification.onclick = (event: any) => {
          const eventTarget = event.target && event.target;
          const notificationData = eventTarget && eventTarget.data;
          const channelId = notificationData ? notificationData.channelId : event.target.tag;

          if (this.platformUtilService.isDesktop()) {
            this.router.navigateByUrl(`/${UI_ROUTES.chat}/${channelId}`);
          } else {
            this.router.navigateByUrl(`/${UI_ROUTES.chat_mobile}/${channelId}`);
          }

          window.focus();
        };
      }
      // -------------------

      // console.log('channels set after update: ***');
      // console.log(newChannelsSet);

      // fetch new client from db and add to usersList

      const channelMembers = channelInstance.state.members.asMutable();

      const usersInStore = this.usersSubject.getValue();

      let usersDoesntExistsInStore = [];

      Object.keys(channelMembers).forEach((userId) => {
        const instanceInUsersList = usersInStore.find((userData) => userData.id === userId);
        if (!instanceInUsersList) usersDoesntExistsInStore.push(userId);
      });

      if (usersDoesntExistsInStore.length) {
        await this.addUsersDataToStore(usersDoesntExistsInStore);
      }

      // //console.log('users doesnt exists in store ***');
      // //console.log(usersDoesntExistsInStore);
      // //console.log('********************************');

      // channelMembers.forEach(userData => {
      //   //console.log('user data ***');
      //   //console.log(userData);
      // });

      // \ fetch new client from db and add to usersList

      this.channelsSubject.next(newChannelsSet);

      // TODO: we can't call watch for this instance. we need to query this channel
      // const channelState = await channel.watch();
      // //console.log(channelState);
    });
  }

  async addUsersDataToStore(userIds) {
    const usersDataFromStore = this.usersSubject.getValue();

    const chatServiceData = await this.streamChatClient.queryUsers(
      { id: { $in: userIds } },
      { last_active: -1 },
      {
        presence: false,
      }
    );

    const chatServiceUsers = chatServiceData.users;

    //console.log("chat service users data: $ ***");
    //console.log(chatServiceUsers);

    this.dbService.getUsersById(userIds).subscribe((res) => {
      //console.log("response on getting users from chat service: $ ***");
      //console.log(res);

      if (res.status === 200) {
        const data = JSON.parse(res.body);

        let usersToAdd = [];

        data.forEach((userDataFromDB) => {
          //console.log("user data from db  $$ ***");
          //console.log(userDataFromDB);

          const userDataFromChat = chatServiceUsers.find((chatServiceData) => chatServiceData.id === userDataFromDB.id);

          //console.log("user data from chat $$ ***");
          //console.log(userDataFromChat);

          if (userDataFromChat) {
            usersToAdd.push({
              ...userDataFromDB,
              ...userDataFromChat,
            });
          }
        });

        //console.log("users to add $$ ***");
        //console.log(usersToAdd);

        if (usersToAdd.length) {
          //console.log("make action $$ ***");
          //console.log(usersToAdd);

          this.usersSubject.next([...usersDataFromStore, ...usersToAdd]);
        }
      }
    });
  }

  // Other functions
  isUserOnline(userId) {
    if (Object.keys(this.presenceStore).length) {
      return this.presenceStore[userId] ? this.presenceStore[userId] === 'online' : false;
    }
  }

  isSuper(userId) {
    var user = this.assistantsSubject.getValue().find((userData) => userData.id === userId);
    if (!user) {
      return false;
    }
    return user.email === DEFAULT_ASSISTANT_EMAIL ? true : false;
  }

  isUserAssistant(userId) {
    var user = this.assistantsSubject.getValue().find((userData) => userData.id === userId);
    return user ? true : false;
  }

  getUsers() {
    return this.usersSubject;
  }

  getMessages() {
    return this.messagesSubject;
  }

  channelsWithUserData$() {
    return combineLatest(this.usersSubject, this.channelsSubject).pipe(
      map(([usersList, roomsList]) => {
        // console.log("some update in channelsWithUserData$ observable: ***");
        // console.log(usersList);
        // console.log(roomsList);
        // console.log("************************");

        // console.log('users list inside complex observable ***');
        // console.log(usersList);

        // console.log('rooms list inside complex observable ***');
        // console.log(roomsList);

        // TODO: replace line below with RXJS operator
        if (!usersList.length || !roomsList.length) return [];

        let roomsToReturn = [];

        // note: I got undefined but when add logs below it started to work
        // console.log('users list ***');
        // console.log(usersList);

        roomsList.forEach((roomData, index) => {
          let client;
          let assistants = [];

          // console.log("Room ",roomData );
          // TODO: provide more proper way
          // const unreadCount = roomData.countUnread();

          // console.log('unread count ***');
          // console.log(unreadCount);

          var roomUsers = roomData.state.members.asMutable();

          // console.log("channel users in observable when run through channels: ***");
          // console.log(roomUsers);

          let userDoesntExists = false;

          Object.keys(roomUsers).forEach((userId) => {
            // console.log("user id we are trying to request: ***");
            // console.log(userId);

            const userDataFromChatService = roomUsers[userId];

            // console.log("user data from chat service: ***");
            // console.log(userDataFromChatService);

            var fullUserData = usersList.find((userData) => userData.id === userId);

            // console.log('full user data: ***');
            // console.log(fullUserData);
            // console.log('***');

            // needed because it's possible case channel is created on user registered but client is not in users list
            if (!fullUserData) {
              userDoesntExists = true;
              return;
            }

            // console.log("full user data in observable when run through channel users: ***");
            // console.log(fullUserData);

            // console.log('full user data ***');
            // console.log(fullUserData);

            if (fullUserData.isAssistant) {
              if (fullUserData.id !== this.currentUserId && fullUserData.email !== DEFAULT_ASSISTANT_EMAIL) {
                assistants.push(fullUserData);
              }
            } else {
              client = fullUserData;
            }
          });

          // console.log("client in observable $$$ ***");
          // console.log(client);

          const dataToPush = {
            channelData: roomData,
            roomAssistants: assistants,
            roomClient: client,
            // unreadCount: unreadCount,
          };

          // console.log("data to push in observable $$$ ***");
          // console.log(dataToPush);

          // miss the room if client doesn't exists there
          if (!client) return;

          roomsToReturn.push(dataToPush);
        });

        console.log('rooms to return in observable $$$ ***', roomsToReturn);

        return roomsToReturn;
      })
    );
  }

  // User db functions
  async updateUserData(userId, userData) {
    (await this.dbService.updateUserData(userId, userData)).subscribe((res) => {
      const users = this.usersSubject.getValue();
      const requestedUserId = users.findIndex((userData) => {
        return userData.id === userId;
      });
      this.usersSubject.next([
        ...users.slice(0, requestedUserId),
        {
          ...users[requestedUserId],
          ...userData,
        },
        ...users.slice(requestedUserId + 1),
      ]);
    });
  }

  async deleteUser(userId) {
    (await this.dbService.deleteUser(userId)).subscribe((res: any) => {
      if (res.user && res.user.id) {
        //console.log(`user ${res.user.id} was deleted`);
        this.removeUserFromSubject(res.user.id);
      }
    });
  }

  // Users chat functions
  blockUser(userId, whoMakeAction) {
    return this.httpClient.post(`${SERVER_URL}/banUser`, {
      userId,
      whoMakeAction,
    });
  }

  unblockUser(userId, whoMakeAction) {
    return this.httpClient.post(`${SERVER_URL}/unbanUser`, {
      userId,
      whoMakeAction,
    });
  }

  async updateStreamChatUserDataInSubject(userId) {
    const updatedStreamChatData = await this.streamChatClient.queryUsers(
      { id: { $in: [userId] } },
      { last_active: -1 },
      {
        presence: false,
        limit: 1,
        offset: 0,
      }
    );

    const updatedStreamChatUser = updatedStreamChatData.users[0];

    let usersDataFromStore = this.usersSubject.getValue();

    const certainUserIndex = usersDataFromStore.findIndex((userData) => userData.id === userId);

    if (certainUserIndex === -1) return;

    usersDataFromStore[certainUserIndex] = {
      ...usersDataFromStore[certainUserIndex],
      ...updatedStreamChatUser,
    };

    this.usersSubject.next(usersDataFromStore);
  }

  removeUserFromSubject(userId) {
    const currentUsersSet = this.usersSubject.getValue();

    const userIndex = currentUsersSet.findIndex((userData) => userData.id === userId);

    if (userIndex === -1) return;

    const newUsersSet = [...currentUsersSet.slice(0, userIndex), ...currentUsersSet.slice(userIndex + 1)];

    this.usersSubject.next(newUsersSet);
  }

  removeChannelFromSubject(channelId) {
    const currentChannelsSet = this.channelsSubject.getValue();

    const channelIndex = currentChannelsSet.findIndex((channelData) => channelData.id === channelId);

    if (channelIndex === -1) return;

    const newChannelsSet = [...currentChannelsSet.slice(0, channelIndex), ...currentChannelsSet.slice(channelIndex + 1)];

    this.channelsSubject.next(newChannelsSet);
  }

  // Get data to using in Modals
  assistantsAvailableToSubscribeInRoom$(channelId): any {
    // //console.log('id in observable: ***');
    // //console.log(channelId);

    // //console.log('current user id: ***');
    // //console.log(this.currentUserId);

    const channel = this.channelsSubject.getValue().find((channelData) => channelData.id === channelId);

    if (channel) {
      const channelMembers = channel.state.members;
      const memberIds = Object.keys(channelMembers);
      // //console.log('channel members: ***');
      // //console.log(channelMembers);
      return this.usersSubject.pipe(
        map((users: Array<User>): Array<User> => {
          return users.filter((userData: User) => {
            return userData.isAssistant && this.currentUserId !== userData.id && !memberIds.includes(userData.id);
          });
        })
      );
    } else {
      return this.usersSubject;
    }

    // //console.log('channel: ***');
    // //console.log(channel);
  }

  assistantsAvailableToRemoveFromRoom$(channelId): Observable<Array<User>> {
    const channel = this.channelsSubject.getValue().find((channelData) => channelData.id === channelId);

    if (channel) {
      const channelMembers = channel.state.members;

      const memberIds = Object.keys(channelMembers);
      // //console.log('channel members: ***');
      // //console.log(channelMembers);
      return this.usersSubject.pipe(
        map((users: Array<User>): Array<User> => {
          return users.filter((userData: User) => {
            return userData.isAssistant && this.currentUserId !== userData.id && memberIds.includes(userData.id);
          });
        })
      );
    } else {
      return this.usersSubject;
    }
  }
}
