import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UI_ROUTES } from './v2/constants/routes.constant';
import { AuthGuard } from './_helpers/auth.guard';
import { OnboardingGuard } from './_helpers/onboarding.guard';

const routes: Routes = [
  {
    path: 'admin-panel',
    canActivateChild: [AuthGuard],
    loadChildren: () => import('./pages/admin-panel/admin-panel.module').then(m => m.AdminPanelPageModule)
  },
  {
    path: 'assistant/home',
    canActivateChild: [AuthGuard],
    loadChildren: () => import('./pages/assistant-app/home/home.module').then(m => m.AssistantHomePageModule)
  },
  {
    path: 'assistant/profile',
    loadChildren: () => import('./pages/assistant-app/profile/profile.module').then(m => m.AssistantProfilePageModule)
  },
  {
    path: 'assistant/reference',
    loadChildren: () => import('./pages/assistant-app/reference-guide/reference-guide.module').then(m => m.ReferenceGuidePageModule)
  },
  {
    path: 'assistant/register',
    loadChildren: () => import('./pages/assistant-app/register/register.module').then(m => m.AssistantRegisterPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'no-access',
    loadChildren: () => import('./no-access/no-access.module').then(m => m.NoAccessPageModule)
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./onboarding/onboarding.module').then(m => m.OnboardingPageModule)
  },
  {
    path: 'onboarding-slides',
    loadChildren: () => import('./onboarding-slides/onboarding-slides.module').then(m => m.OnboardingSlidesPageModule)
  },
  {
    path: 'cc-edit',
    loadChildren: () => import('./cc-edit/cc-edit.module').then(m => m.CcEditPageModule)
  },
  {
    path: 'receipt',
    loadChildren: () => import('./receipt/receipt.module').then(m => m.ReceiptPageModule)
  },
  {
    path: 'onboardclient-form',
    loadChildren: () => import('./onboardclient-form/onboardclient-form.module').then(m => m.OnboardclientFormPageModule)
  },
  {
    path: 'onboardclient-vid1',
    loadChildren: () => import('./onboardclient-vid1/onboardclient-vid1.module').then(m => m.OnboardclientVid1PageModule)
  },
  {
    path: 'onboardclient-vid2',
    loadChildren: () => import('./onboardclient-vid2/onboardclient-vid2.module').then(m => m.OnboardclientVid2PageModule)
  },
  {
    path: 'onboardclient-vid3',
    loadChildren: () => import('./onboardclient-vid3/onboardclient-vid3.module').then(m => m.OnboardclientVid3PageModule)
  },
  {
    path: 'onboardclient-view',
    loadChildren: () => import('./onboardclient-view/onboardclient-view.module').then(m => m.OnboardclientViewPageModule)
  },
  {
    path: UI_ROUTES.chat, // Private chat web
    canActivateChild: [OnboardingGuard],
    loadChildren: () => import('./private-chat/private-chat.module').then(m => m.PrivateChatPageModule)
  },
  {
    path: UI_ROUTES.chat_mobile, // Private chat mobile
    canActivateChild: [OnboardingGuard],
    loadChildren: () => import('./private-chat-mobile/private-chat-mobile.module').then(m => m.PrivateChatMobilePageModule)
  },
  {
    path: UI_ROUTES.monday_mobile, // Private chat mobile
    loadChildren: () => import('./monday-mobile/monday-mobile.module').then(m => m.MondayMobileModule)
  },
  {
    path: UI_ROUTES.taskv2_mobile,
    loadChildren: () => import("./taskv2-mobile/taskv2-mobile.module").then(m => m.Taskv2MobileModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfilePageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'reset-otp',
    loadChildren: () => import('./pages/reset-otp/reset-otp.module').then(m => m.ResetOtpPageModule)
  },
  {
    path: 'reset-page',
    loadChildren: () => import('./reset-page/reset-page.module').then(m => m.ResetPagePageModule)
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./pages/sign-up/sign-up.module').then(m => m.SignUpPageModule)
  },
  {
    path: 'schedule-call',
    loadChildren: () => import('./schedule-call/schedule-call.module').then(m => m.ScheduleCallPageModule)
  },
  {
    path: 'schedule-call-delete-pause',
    loadChildren: () => import('./schedule-call-delete/schedule-call.module').then(m => m.ScheduleCallPageModule)
  },
  {
    path: 'taskv2',
    loadChildren: () => import('./pages/taskv2/taskv2.module').then(m => m.Taskv2PageModule)
  },
  {
    path: 'taskv2/:id',
    loadChildren: () => import('./pages/taskv2/taskv2.module').then(m => m.Taskv2PageModule)
  },
  {
    path: `${UI_ROUTES.taskv2_mobile}/:id`,
    loadChildren: () => import("./taskv2-mobile/taskv2-mobile.module").then(m => m.Taskv2MobileModule)
  },
  {
    path: 'assistant/todos',
    loadChildren: () => import('./pages/todos-assistant/todos-assistant.module').then(m => m.TodosAssistantModule)
  },
  {
    path: 'time-tracker',
    canActivateChild: [OnboardingGuard],
    loadChildren: () => import('./time-tracker/time-tracker.module').then(m => m.TimeTrackerPageModule)
  },
  {
    path: 'va-yesno',
    loadChildren: () => import('./pages/va-yesno/va-yesno.module').then(m => m.VaYesnoPageModule)
  },
  {
    path: 'va-hours',
    loadChildren: () => import('./pages/va-hours/va-hours.module').then(m => m.VaHoursPageModule)
  },
  {
    path: 'va-service',
    loadChildren: () => import('./pages/va-service/va-service.module').then(m => m.VaServicePageModule)
  },
  {
    path: 'verification',
    loadChildren: () => import('./verification/verification.module').then(m => m.VerificationPageModule)
  },
  {
    path: 'verify-phone',
    loadChildren: () => import('./pages/verify-phone/verify-phone.module').then(m => m.VerifyPhonePageModule)
  },
  {
    path: 'receipt',
    loadChildren: () => import('./receipt/receipt.module').then(m => m.ReceiptPageModule)
  },
  {
    path: 'onboarding-mobile-start',
    // tslint:disable-next-line: max-line-length
    loadChildren: () => import('./onboarding-mobile/onboarding-mobile-start/onboarding-mobile-start.module').then(m => m.OnboardingMobileStartPageModule)
  },
  {
    path: 'onboarding-mobile-register',
    // tslint:disable-next-line: max-line-length
    loadChildren: () => import('./onboarding-mobile/onboarding-mobile-register/onboarding-mobile-register.module').then(m => m.OnboardingMobileRegisterPageModule)
  },
  {
    path: 'onboarding-mobile-schedule-free-consultation',
    // tslint:disable-next-line: max-line-length
    loadChildren: () => import('./onboarding-mobile/onboarding-mobile-schedule-free-consultation/onboarding-mobile-schedule-free-consultation.module').then(m => m.OnboardingMobileScheduleFreeConsultationPageModule)
  },
  {
    path: 'onboarding-mobile-schedule-onboarding',
    // tslint:disable-next-line: max-line-length
    loadChildren: () => import('./onboarding-mobile/onboarding-mobile-schedule-onboarding/onboarding-mobile-schedule-onboarding.module').then(m => m.OnboardingMobileScheduleOnboardingPageModule)
  },
  {
    path: 'housekeeping',
    canActivateChild: [OnboardingGuard],
    loadChildren: () => import('./housekeeping/housekeeping.module').then(m => m.HousekeepingPageModule)
  },
  {
    path: 'process-doc',
    canActivateChild: [OnboardingGuard],
    loadChildren: () => import('./pages/process-doc/process-doc.module').then(m => m.ProcessDocPageModule)
  },
  {
    path: 'reset-password/:token',
    loadChildren: () => import('./pages/reset-page/reset-page.module').then(m => m.ResetPagePageModule),
  },
  {
    path: 'notification-alert',
    loadChildren: () => import('./notification-alert/notification-alert.module').then(m => m.NotificationAlertPageModule)
  },
  {
    path: 'recovery-pass',
    loadChildren: () => import('./pages/recovery-pass/recovery-pass.module').then(m => m.RecoveryPassPageModule)
  },
  {
    path: 'recovery-pass/:token',
    loadChildren: () => import('./pages/recovery-pass-set/recovery-pass-set.module').then(m => m.RecoveryPassSetPageModule)
  },
  {
    path: 'assistant/calendar-book',
    loadChildren: () => import('./pages/assistant-app/calendar-book/calendar-book.module').then(m => m.CalendarBookPageModule)
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
