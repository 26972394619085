/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "@angular/router";
import * as i6 from "./app.component";
import * as i7 from "@ionic-native/splash-screen/ngx/index";
import * as i8 from "@ionic-native/status-bar/ngx/index";
import * as i9 from "./_services/auth.service";
import * as i10 from "./_services/assistants-to-clients.service";
import * as i11 from "@ionic/storage";
import * as i12 from "@ionic-native/badge/ngx/index";
import * as i13 from "@ionic-native/firebase-x/ngx/index";
import * as i14 from "@awesome-cordova-plugins/deeplinks/ngx/index";
import * as i15 from "./v2/services/utils/platform.util.service";
import * as i16 from "@ionic-native/in-app-browser/ngx/index";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "user-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "avatar-c"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["onerror", "this.src='../assets/icon/avatar.png'"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "name"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userInfo.streamChatData.image; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.userInfo.name; _ck(_v, 4, 0, currVal_1); }); }
function View_AppComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "item-container show-hand"]], [[4, "marginBottom", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((i1.ɵnov(_v.parent, 1).close() && _co.goTo(_v.context.$implicit)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["onerror", "this.src='../assets/icon/menu-profile.png'"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_v.context.index + 2) < _co.navigate.length) ? "30px" : "50px"); _ck(_v, 0, 0, currVal_0); var currVal_1 = ("assets/icon/" + _v.context.$implicit.icon); _ck(_v, 1, 0, currVal_1); var currVal_2 = _v.context.$implicit.title; _ck(_v, 3, 0, currVal_2); }); }
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "ion-menu", [["contentId", "content1"], ["menuId", "first"], ["side", "start"], ["type", "push"]], null, null, null, i2.View_IonMenu_0, i2.RenderType_IonMenu)), i1.ɵdid(1, 49152, [[1, 4], ["mainMenu", 4]], 0, i3.IonMenu, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { contentId: [0, "contentId"], menuId: [1, "menuId"], side: [2, "side"], type: [3, "type"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 8, "ion-content", [], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(3, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 6, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["class", "menu-back show-hand"], ["src", "assets/icon/menu-back.png"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "divider"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_3)), i1.ɵdid(10, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "content1"; var currVal_1 = "first"; var currVal_2 = "start"; var currVal_3 = "push"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = (_co.userInfo && _co.userInfo.streamChatData); _ck(_v, 7, 0, currVal_4); var currVal_5 = _co.navigate; _ck(_v, 10, 0, currVal_5); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { mainMenu: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 5, "ion-app", [], null, null, null, i2.View_IonApp_0, i2.RenderType_IonApp)), i1.ɵdid(2, 49152, null, 0, i3.IonApp, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_AppComponent_1)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 16777216, null, 0, 1, "ion-router-outlet", [["class", "mainRouterOutlet"], ["id", "content1"]], null, null, null, null, null)), i1.ɵdid(6, 212992, null, 0, i3.IonRouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], [8, null], i3.Config, i3.NavController, i4.Location, i1.ElementRef, i5.Router, i1.NgZone, i5.ActivatedRoute, [3, i3.IonRouterOutlet]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isAuth; _ck(_v, 4, 0, currVal_0); _ck(_v, 6, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 245760, null, 0, i6.AppComponent, [i3.Platform, i7.SplashScreen, i8.StatusBar, i9.AuthService, i10.AssistantsToClientsService, i11.Storage, i5.Router, i12.Badge, i13.FirebaseX, i14.Deeplinks, i1.NgZone, i15.PlatformUtilService, i16.InAppBrowser], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i6.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
