export const environment = {
  production: true,
  staging: false,
  firebaseConfig: {
    apiKey: "AIzaSyDcme3X7nDUx_rCdnABTIfyYfMNmwd_Cq0",
    authDomain: "chatterboss-payments.firebaseapp.com",
    databaseURL: "https://chatterboss-payments.firebaseio.com",
    projectId: "chatterboss-payments",
    storageBucket: "chatterboss-payments.appspot.com",
    messagingSenderId: "123087769181",
    appId: "1:123087769181:web:1d150d28d9adc189f447b4",
  },
  stripeKey: "pk_live_5HjI80SDlRioSKkaX7rLzTY7",
};
export const SERVER_URL = "https://next-appserver.chatterboss.com";
export const STREAM_KEY = "fyawmqrej5fv";
export const STREAM_SECRET =
  "tr4d32hx6fgp8mvarx2kvppr4dzrcw2ff6cgjgqvymfdbq2gumq8wuyazne4axaj";

export const stripeAPI = "pk_live_5HjI80SDlRioSKkaX7rLzTY7";

export const SystemVariableProvider = {
  REGION: "us-west-2",
  BUCKET_NAME: "app.chatterboss.com",
  IDENTITY_POOL_ID: "AKIA4PKT3QGCUSSVDC5G",
}

export const EXTERNAL_PAYMENT_OPTIONS_URL = 'https://www.chatterboss.com/pricing';

export const PAYMENT_URL = "https://appserver.chatterboss.com/payments";

export const VAPID_PUBLIC =
    "BMcEDTJnY4qPIs7yfIDwEpEB2BzvzPKZTCI6MNkhGNxdq8SXwMRt5KFwK2iXlQQdubr4YwnJjjN9f7N-S-gJe00";

export const DEFAULT_ASSISTANT_EMAIL = "Assistant@chatterboss.com";
export const AVATAR_URL = "https://next-appserver.chatterboss.com";

export const HOUSEKEEPING_PDF_LINK = 'https://s3.us-west-2.amazonaws.com/app.chatterboss.com/ChatterBoss_Housekeeping_2023.pdf';
export const TERMS_CONDITIONS_LINK = 'https://www.chatterboss.com/terms-conditions';
export const PAYMENT_TEXTS = {
  AMOUNT: 418.95,
  AMOUNT_2: 399,
  PERCENT_AMOUNT: 5,
  TEXT_1: "- One hour session with our on-boarding strategists to create your getting started plan",
  TEXT_2: "- One hour onboarding call to meet your executive assistant and kick things off",
  TEXT_3: "- Custom 6 month delegating roadmap",
  TEXT_4: "- Unlimited access to your dedicated client success manager"
}
export const REFERENCE_GUIDE_LINK = 'https://sites.google.com/chatterboss.com/vareferenceguide/home';
export const MEET_WITH_ASSISTANT_URL = 'https://calendly.com/d/dj6-kyg-v6y/assistant-success-call-20-minute';
export const PLAID_ENV = 'production';
export const PLAID_KEY = '9806e1e2742965ec9ce95bf671d025';
