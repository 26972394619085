import { AuthService } from '../_services/auth.service';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "../_services/auth.service";
import * as i2 from "@angular/router";
var AuthGuard = /** @class */ (function () {
    function AuthGuard(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    AuthGuard.prototype.canActivateChild = function (next, state) {
        var _this = this;
        if (this.authService.isLoggedIn()) {
            return true;
        }
        else {
            return new Promise(function (resolve) {
                _this.authService.checkTokenExists().then(function (tokenExists) {
                    if (tokenExists) {
                        resolve(true);
                    }
                    else {
                        _this.router.navigateByUrl('/login');
                        resolve(false);
                    }
                });
            });
        }
    };
    AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
export { AuthGuard };
