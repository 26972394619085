import * as tslib_1 from "tslib";
import { HttpClient } from "@angular/common/http";
import { FirebaseX } from "@ionic-native/firebase-x/ngx";
import { Platform } from "@ionic/angular";
import { Storage } from "@ionic/storage";
import { BehaviorSubject, combineLatest } from "rxjs";
import { distinctUntilChanged, map, tap } from "rxjs/operators";
import { DEFAULT_ASSISTANT_EMAIL, SERVER_URL } from "../../environments/environment";
import { ChatService } from "./chat.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ionic/storage";
import * as i3 from "./chat.service";
import * as i4 from "@ionic/angular";
import * as i5 from "@ionic-native/firebase-x/ngx/index";
var AuthService = /** @class */ (function () {
    function AuthService(httpClient, storage, chatService, platform, firebase) {
        this.httpClient = httpClient;
        this.storage = storage;
        this.chatService = chatService;
        this.platform = platform;
        this.firebase = firebase;
        this.AUTH_SERVER = SERVER_URL;
        this.authState = new BehaviorSubject(false);
        this.userInfo = new BehaviorSubject({});
        this.deviceToken = new BehaviorSubject("");
        this.isUserSignedIn = new BehaviorSubject(false);
        this.initIDFA = new BehaviorSubject('null');
    }
    AuthService.prototype.register = function (userInfo, isAssistant) {
        if (isAssistant === void 0) { isAssistant = false; }
        userInfo.isAssistant = !!isAssistant;
        return this.httpClient.post(this.AUTH_SERVER + "/register", userInfo);
    };
    AuthService.prototype.checkForDuplicate = function (userInfo) {
        return this.httpClient.post(this.AUTH_SERVER + "/checkForDuplicate", userInfo);
    };
    AuthService.prototype.login = function (userInfo) {
        var _this = this;
        return this.httpClient.post(this.AUTH_SERVER + "/login", userInfo).pipe(tap(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var userWasBanned, isPause, toDelete;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        userWasBanned = res && res.user_info && res.user_info.streamChatData && res.user_info.streamChatData.banned;
                        isPause = res && res.user_info && res.user_info.isPause;
                        toDelete = res && res.user_info && res.user_info.toDelete;
                        if (isPause || toDelete) {
                            return [2 /*return*/];
                        }
                        if (!(res.status !== 404 && !userWasBanned)) return [3 /*break*/, 6];
                        this.userInfo.next(res.user_info);
                        return [4 /*yield*/, this.storage.set("ACCESS_TOKEN", res.access_token)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.storage.set("EXPIRES_IN", res.expires_in)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.storage.set("USER_INFO", res.user_info)];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, this.storage.set("USER_ID", res.user_id)];
                    case 4:
                        _a.sent();
                        return [4 /*yield*/, this.storage.set("IS_USER_ASSISTANT", res.user_info.isAssistant)];
                    case 5:
                        _a.sent();
                        this.authState.next(true);
                        _a.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        }); }));
    };
    AuthService.prototype.userAuthState$ = function () {
        return combineLatest(this.authState, this.userWasChanged$()).pipe(map(function (_a) {
            var authState = _a[0], userInfo = _a[1];
            return authState && !!userInfo.id;
        }));
    };
    AuthService.prototype.userWasChanged$ = function () {
        return this.userInfo.pipe(distinctUntilChanged(function (prev, curr) {
            return !curr || prev.id === curr.id;
        }));
    };
    AuthService.prototype.fetchUserInfo = function (userId) {
        var _this = this;
        var userInfoSubscription = this.httpClient.get(this.AUTH_SERVER + "/user/" + userId);
        userInfoSubscription.subscribe(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var status, isAuth;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        status = res.status;
                        isAuth = this.authState.getValue();
                        if (!(status != 404)) return [3 /*break*/, 2];
                        this.userInfo.next(res);
                        return [4 /*yield*/, this.storage.set("USER_INFO", res)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        if (!isAuth) {
                            this.authState.next(true);
                        }
                        return [2 /*return*/];
                }
            });
        }); });
        return userInfoSubscription;
    };
    AuthService.prototype.getUserInfoById = function (userId) {
        return this.httpClient.get(this.AUTH_SERVER + "/user/" + userId);
    };
    AuthService.prototype.isSuperAssistant = function () {
        return (this.authState &&
            this.userInfo.getValue().email === DEFAULT_ASSISTANT_EMAIL);
    };
    AuthService.prototype.saveUserData = function (userData) {
        return this.httpClient.put(SERVER_URL + "/user/" + this.userInfo.getValue().id, userData);
    };
    AuthService.prototype.enableAuthFlag = function () {
        this.authState.next(true);
    };
    AuthService.prototype.isSuccessfullySignedIn = function () {
        return this.authState.getValue() && !!this.userInfo.getValue().id;
    };
    AuthService.prototype.registerAssistant = function (userInfo) {
        return this.register(userInfo, true);
    };
    AuthService.prototype.getUserInfo = function () {
        return this.userInfo.getValue();
    };
    AuthService.prototype.logout = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.platform.is('cordova')) {
                            this.firebase.clearAllNotifications();
                            this.resetDeviceToken().subscribe();
                        }
                        return [4 /*yield*/, this.storage.remove("ACCESS_TOKEN")];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.storage.remove("EXPIRES_IN")];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.storage.remove("USER_INFO")];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, this.storage.remove("USER_ID")];
                    case 4:
                        _a.sent();
                        return [4 /*yield*/, this.storage.remove("IS_USER_ASSISTANT")];
                    case 5:
                        _a.sent();
                        this.userInfo.next(null);
                        this.authState.next(false);
                        this.chatService.disconnectFromStreamChat();
                        return [2 /*return*/];
                }
            });
        });
    };
    AuthService.prototype.checkTokenExists = function () {
        var _this = this;
        console.log("Checking token..");
        return new Promise(function (resolve) {
            _this.storage.get("ACCESS_TOKEN").then(function (token) {
                if (token !== null) {
                    console.log("Token exists, ", token);
                    // this.authState.next(true);
                    resolve(true);
                }
                else {
                    console.log("No Token exists, ", token);
                    // this.authState.next(false);
                    resolve(false);
                }
            });
        });
    };
    AuthService.prototype.setDeviceToken = function (token) {
        this.deviceToken.next(token);
    };
    AuthService.prototype.getDeviceToken = function () {
        return this.deviceToken.getValue();
    };
    AuthService.prototype.registerDeviceToken = function (userId, deviceToken) {
        return this.httpClient.post(SERVER_URL + "/deviceToken", {
            userId: userId,
            token: deviceToken,
        });
    };
    AuthService.prototype.resetDeviceToken = function () {
        // this method needs to be called before user info will be cleared
        var currentUserData = this.userInfo.getValue();
        if (!currentUserData)
            return;
        var currentUserId = currentUserData.id;
        return this.httpClient.post(SERVER_URL + "/resetDeviceToken", {
            userId: currentUserId,
        });
    };
    AuthService.prototype.resetPassword = function (phone, newPassword) {
        return this.httpClient.post(this.AUTH_SERVER + "/resetPassword", {
            phone: phone,
            newPassword: newPassword,
        })
            .pipe(map(function (data) {
            return data;
        }));
    };
    AuthService.prototype.isLoggedIn = function () {
        return this.authState.value;
    };
    AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Storage), i0.ɵɵinject(i3.ChatService), i0.ɵɵinject(i4.Platform), i0.ɵɵinject(i5.FirebaseX)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
