import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { IonicModule } from '@ionic/angular';
import { IframeComponent } from './iframe/iframe.component';
import { GridBody } from './taskv2-components/grid-body/grid-body';
import { NewTaskRow } from './taskv2-components/new-task-row/new-task-row';
import { GridHeaders } from './taskv2-components/grid-headers/grid-headers';
import { DeleteAsk } from './taskv2-components/delete-ask/delete-ask';
import { TaskActions } from './taskv2-components/task-actions/task-actions';
import { TaskToast } from './taskv2-components/task-toast/task-toast';
import { TaskChat } from './taskv2-components/taskv2-chat/task-chat';
import { UnarchiveAsk } from './taskv2-components/unarchive-ask/unarchive-ask';
import { FormsModule } from '@angular/forms';
import { GridBodyMobileComponent } from './taskv2-components/grid-body-mobile/grid-body-mobile.component';
import { GridHeaderMobileComponent } from './taskv2-components/grid-header-mobile/grid-header-mobile.component';
import { LinksPipe } from '../pipes/links.pipe';

@NgModule({
  declarations: [
    HeaderComponent,
    IframeComponent,
    GridBody,
    NewTaskRow,
    GridHeaders,
    DeleteAsk,
    TaskActions,
    TaskToast,
    TaskChat,
    UnarchiveAsk,
    GridBodyMobileComponent,
    GridHeaderMobileComponent,
    LinksPipe
  ],
  imports: [CommonModule, IonicModule, FormsModule],
  exports: [
    HeaderComponent,
    IframeComponent,
    GridBody,
    NewTaskRow,
    GridHeaders,
    DeleteAsk,
    TaskActions,
    TaskToast,
    TaskChat,
    UnarchiveAsk,
    GridBodyMobileComponent,
    GridHeaderMobileComponent
  ],
})
export class ComponentsModule {}
