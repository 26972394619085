import * as tslib_1 from "tslib";
import { NgZone, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
import { Badge } from '@ionic-native/badge/ngx';
// import { Deeplinks } from "@ionic-native/deeplinks/ngx";
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonMenu, Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { DEFAULT_ASSISTANT_EMAIL } from '../environments/environment';
import { UI_ROUTES } from './v2/constants/routes.constant';
import { PlatformUtilService } from './v2/services/utils/platform.util.service';
import { AssistantsToClientsService } from './_services/assistants-to-clients.service';
import { AuthService } from './_services/auth.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
var NO_REDIRECTED_ROUTES = ['/assistant/register', '/register', '/onboarding-slides', '/recovery-pass', '/recovery-pass/*'];
var AppComponent = /** @class */ (function () {
    function AppComponent(platform, splashScreen, statusBar, authService, assistantToClientService, storage, router, badge, firebase, deeplinks, ngZone, platformUtilService, inAppBrowser) {
        this.platform = platform;
        this.splashScreen = splashScreen;
        this.statusBar = statusBar;
        this.authService = authService;
        this.assistantToClientService = assistantToClientService;
        this.storage = storage;
        this.router = router;
        this.badge = badge;
        this.firebase = firebase;
        this.deeplinks = deeplinks;
        this.ngZone = ngZone;
        this.platformUtilService = platformUtilService;
        this.inAppBrowser = inAppBrowser;
        this.isAssistant = false;
        this.isAuth = false;
        this.userInfo = {};
        this.assignees = [];
        this.defaultAssignee = '';
        this.isDesktop = false;
        this.isSuperAdmin = false;
        this.menuFirstLoadAlreadyWas = false;
        this.isPayed = false;
        this.deviceToken = '';
        this.isCordovaIOS = this.platform.is('cordova') && this.platform.is('ios');
        this.initializeApp();
    }
    AppComponent.prototype.ngOnInit = function () { };
    AppComponent.prototype.addGtmEvent = function (gtmTag) {
        if (window) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(gtmTag);
        }
    };
    // works for logout/login
    // userWasChanged$() {
    //   return this.authService.userInfo.pipe(
    //     distinctUntilChanged((prev: User, curr: User): any => {
    //       console.log('inside distinct ***');
    //       console.log(prev);
    //       console.log(curr);
    //       // if (!(prev === null && curr.id)) {
    //       //   return true;
    //       // } else {
    //       //   return false;
    //       // }
    //       return !curr || prev.id === curr.id;
    //       // return prev.id === curr.id;
    //     })
    //   )
    // }
    AppComponent.prototype.redirectIfNeeded = function () {
        console.log('AppComponent.redirectIfNeeded entry point ***');
        if (window.location.pathname.match(/^\/$/g)) {
            console.log('AppComponent.redirectIfNeeded redirects needed ***');
            if (this.isAssistant) {
                console.log('AppComponent.redirectIfNeeded isAssistant ***');
                this.router.navigateByUrl('/assistant/home');
            }
            else {
                console.log('AppComponent.redirectIfNeeded not assistant ***');
                if (this.platformUtilService.isDesktop()) {
                    this.router.navigateByUrl("/" + UI_ROUTES.chat);
                }
                else {
                    this.router.navigateByUrl("/" + UI_ROUTES.chat_mobile);
                }
            }
        }
    };
    AppComponent.prototype.loadAppData = function () {
        var _this = this;
        if (this.isAssistant) {
            // this.taskManager
            //   .fetchTasksByAssignee(this.userInfo.id)
            //   .subscribe((res: any[]) => {
            //     this.taskManager.loadTasks(res);
            //   });
        }
        else {
            this.assigneesSubscription = this.assistantToClientService.fetchAndPopulateAssignees$(this.userInfo.id).subscribe(function (res) {
                if (res.length) {
                    _this.assignees = res;
                    _this.defaultAssignee = _this.assignees[0];
                }
            });
            // this.taskManager
            //   .fetchTasksByCreator(this.userInfo.id)
            //   .subscribe((res: any[]) => {
            //     this.taskManager.loadTasks(res);
            //   });
        }
    };
    AppComponent.prototype.ngOnDestroy = function () {
        console.log('AppComponent.ngOnDestroy');
        this.userInfoSubscription.unsubscribe();
        this.assigneesSubscription.unsubscribe();
        if (this.deviceTokenSubscription) {
            this.deviceTokenSubscription.unsubscribe();
        }
    };
    AppComponent.prototype.initializeApp = function () {
        var _this = this;
        this.platform.ready().then(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var userId, locationPathname, hasNoRedirect, slidesComplete, _a, hasShowNotificationAlertPage, _b, hasPNPermission;
            var _this = this;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        console.log('AppComponent.initializeApp - Platform initialized');
                        // Set statusBar and hide splashScreen
                        this.statusBar.styleDefault();
                        this.splashScreen.hide();
                        // Default add GTM Script for Android and Web platform
                        if (!this.isCordovaIOS) {
                            this.addGTMScript();
                        }
                        // If mobile cordova platform
                        if (this.platform.is('cordova')) {
                            if (this.platform.is('android')) {
                                // Set instabug
                                this.initializeInstabug();
                                this.reqPermissionPN();
                            }
                            else {
                                this.checkiOSIDFA();
                            }
                            this.initializePNListeners();
                            this.deeplinks
                                .route({
                                '/recovery-pass/:token': 'recovery-pass',
                            })
                                .subscribe(function (match) {
                                _this.ngZone.run(function () {
                                    console.log('DEEPLINKS: ', match);
                                    var token = match.$args.token;
                                    _this.router.navigateByUrl("/recovery-pass/" + token);
                                });
                            }, function (nomatch) {
                                // nomatch.$link - the full link data
                                console.error("Got a deeplink that didn't match", nomatch);
                            });
                            // Platform resume event listener
                            this.platform.resume.subscribe(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    this.firebase.clearAllNotifications();
                                    return [2 /*return*/];
                                });
                            }); });
                        }
                        // tslint:disable-next-line: triple-equals
                        if (this.userInfo.Onboarding == true) {
                            this.isPayed = true;
                        }
                        else {
                            this.isPayed = false;
                        }
                        this.isDesktop = this.platform.is('desktop');
                        return [4 /*yield*/, this.storage.get('USER_ID')];
                    case 1:
                        userId = _c.sent();
                        console.log('AppComponent.initializeApp show user id: ', userId);
                        this.authService.userAuthState$().subscribe(function (state) {
                            if (state === true) {
                                console.log('AppComponent.ngOnInit user is authorized ***');
                                _this.isAuth = true;
                                _this.userInfoSubscription = _this.authService.userInfo.subscribe(function (userInfo) {
                                    console.log('AppComponent.ngOnInit userInfo was changed (this.authService.userInfo.subscribe) ***');
                                    // console.log('AppComponent.ngOnInit user is authorized ***');
                                    _this.userInfo = userInfo;
                                    if (_this.userInfo) {
                                        localStorage.setItem('customerID', _this.userInfo.CustomerID);
                                    }
                                    if (_this.platform.is('cordova')) {
                                        console.log('matched cordova platfrom ****');
                                        _this.deviceTokenSubscription = _this.authService.deviceToken.subscribe(function (token) {
                                            console.log('Device Token Changed ***:');
                                            console.log(token);
                                            // if (token && (token !== this.userInfo.deviceToken)) {
                                            if (token) {
                                                _this.authService.registerDeviceToken(_this.userInfo.id, token).subscribe(function (res) {
                                                    console.log('Register Token Result: ***');
                                                    console.log(res);
                                                });
                                            }
                                        });
                                    }
                                    if (_this.userInfo) {
                                        _this.isSuperAdmin = _this.userInfo.email === DEFAULT_ASSISTANT_EMAIL;
                                        _this.isAssistant = _this.userInfo.isAssistant;
                                        // this.loadAppData();
                                        _this.initializeMenu();
                                        _this.redirectIfNeeded();
                                    }
                                    else {
                                        // for logout state update case
                                        _this.isSuperAdmin = false;
                                        _this.isAssistant = false;
                                    }
                                });
                                // works for login/logout and on reload when user is signed in
                                _this.authService.userWasChanged$().subscribe(function () {
                                    console.log('AppComponent.ngOnInit user was changed (this.authService.userInfo.subscribe) ***');
                                    _this.isSuperAdmin = _this.userInfo.email === DEFAULT_ASSISTANT_EMAIL;
                                    _this.isAssistant = _this.userInfo.isAssistant;
                                    _this.loadAppData();
                                    _this.initializeMenu();
                                    _this.redirectIfNeeded();
                                });
                                // this.isAssistant = this.userInfo.isAssistant;
                                // this.loadAppData();
                                // this.initializeMenu();
                                // this.redirectIfNeeded();
                            }
                        });
                        locationPathname = window.location.pathname.split('/');
                        hasNoRedirect = NO_REDIRECTED_ROUTES.filter(function (url) {
                            var parts = url.split('/');
                            if (parts.length === locationPathname.length) {
                                var check = 0;
                                for (var i = 0; i < parts.length; i++) {
                                    if (parts[i] === locationPathname[i]) {
                                        check++;
                                    }
                                    else if (parts[i] === '*') {
                                        check++;
                                    }
                                }
                                return check === parts.length;
                            }
                            else {
                                return false;
                            }
                        });
                        if (hasNoRedirect.length >= 1 && !userId) {
                            console.log('AppComponent.initializeApp - Prevent NO_REDIRECTED_ROUTES');
                            return [2 /*return*/];
                        }
                        console.log('AppComponent.initializeApp  - Pass NO_REDIRECTED_ROUTES');
                        this.router.events.forEach(function (item) {
                            if (item instanceof NavigationEnd) {
                                var gtmTag_1 = {
                                    event: 'page',
                                    pageName: item.url,
                                };
                                if (_this.platform.is('ios') && _this.platform.is('cordova')) {
                                    cordova.plugins.idfa.getInfo().then(function (info) {
                                        // The app is closed if the user goes to the device settings and change the permission, so
                                        // tslint:disable-next-line: max-line-length
                                        // the following check should not be necessary. Leaving it, just in case there is another way to set that permission to false.
                                        if (info.trackingPermission === cordova.plugins.idfa.TRACKING_PERMISSION_AUTHORIZED) {
                                            _this.addGtmEvent(gtmTag_1);
                                        }
                                    });
                                }
                                else {
                                    _this.addGtmEvent(gtmTag_1);
                                }
                            }
                        });
                        if (!this.platform.is('cordova')) return [3 /*break*/, 5];
                        _a = Boolean;
                        return [4 /*yield*/, this.storage.get('slidesComplete')];
                    case 2:
                        slidesComplete = _a.apply(void 0, [_c.sent()]);
                        _b = Boolean;
                        return [4 /*yield*/, this.storage.get('hasShowNotificationAlertPage')];
                    case 3:
                        hasShowNotificationAlertPage = _b.apply(void 0, [_c.sent()]);
                        return [4 /*yield*/, this.firebase.hasPermission()];
                    case 4:
                        hasPNPermission = _c.sent();
                        // Check if user has permission on notifications and flag to show page "notification-alert"
                        if (!hasPNPermission && !hasShowNotificationAlertPage) {
                            this.router.navigateByUrl('/notification-alert');
                            return [2 /*return*/];
                        }
                        else {
                            if (!slidesComplete) {
                                console.log('Show onboarding Slides');
                                this.router.navigateByUrl('/onboarding-slides');
                                return [2 /*return*/];
                            }
                            else {
                                if (this.platform.is('ios')) {
                                    // Set instabug
                                    this.initializeInstabug();
                                }
                            }
                        }
                        _c.label = 5;
                    case 5:
                        if (userId) {
                            this.authService.fetchUserInfo(userId).subscribe(function (res) {
                                console.log('AppComponent.initializeApp - fetchUserInfo by userId res:', res);
                                // tslint:disable-next-line: triple-equals
                                if (res.status == 404) {
                                    _this.router.navigateByUrl('/login');
                                }
                            });
                        }
                        else {
                            this.router.navigateByUrl('/login');
                        }
                        return [2 /*return*/];
                }
            });
        }); });
    };
    AppComponent.prototype.checkiOSIDFA = function () {
        var _this = this;
        this.authService.initIDFA.subscribe(function (r) {
            console.log('initIDFA--->', r);
            if (r !== 'null') {
                if (r === 'ok') {
                    _this.addGTMScript();
                }
                else {
                    _this.addGtmEvent({ 'gtm.start': new Date().getTime(), event: 'gtm.js', 'gtm.uniqueEventId': 1 });
                    _this.addGtmEvent({ event: 'gtm.dom', 'gtm.uniqueEventId': 2 });
                    _this.addGtmEvent({ event: 'gtm.load', 'gtm.uniqueEventId': 3 });
                }
            }
        });
    };
    AppComponent.prototype.addGTMScript = function () {
        // If tracking is Authorized, add the tracking script to the html.
        var script = document.createElement('script');
        script.innerHTML = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n    })(window,document,'script','dataLayer','GTM-TX84Z7P');";
        var noScript = document.createElement('noscript');
        var iframe = document.createElement('iframe');
        iframe.src = 'https://www.googletagmanager.com/ns.html?id=GTM-TX84Z7P';
        iframe.width = '0';
        iframe.height = '0';
        iframe.style.display = 'none';
        iframe.style.visibility = 'hidden';
        noScript.appendChild(iframe);
        document.head.appendChild(script);
        document.body.appendChild(noScript);
    };
    AppComponent.prototype.initializeMenu = function () {
        var _this = this;
        var clientNav = this.createClientNav();
        if (this.userInfo.sopURL) {
            clientNav = this.insertProcessDocumentOption(clientNav);
        }
        console.log('info del usuario', this.userInfo);
        // console.log("userInfo.Onboarding",this.userInfo.Onboarding);
        // if (this.userInfo.Onboarding==1) {
        //   clientNav.splice(3, 1);
        // } else {
        //   // clientNav.splice(5, 1);
        // }
        var superAdminNav = [
            {
                icon: 'menu-chat.png',
                title: 'Home',
                url: '/assistant/home',
            },
            {
                icon: 'menu-profile.png',
                title: ' Profile Settings',
                url: '/assistant/profile',
            },
            {
                icon: 'menu-profile.png',
                title: 'Admin Panel',
                url: '/admin-panel',
            },
            // {
            //   title: "Check Analytics",
            //   clickHandler: () => {
            //     (<any>window).gtag_report_conversion("check_analytics");
            //     // window.gtag_report_conversion('check_analytics');
            //   },
            // },
            {
                icon: 'menu-logout.png',
                title: 'Log Out',
                clickHandler: function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.authService.logout()];
                            case 1:
                                _a.sent();
                                if (this.mainMenu.isOpen) {
                                    this.mainMenu.close();
                                }
                                this.router.navigateByUrl('/login');
                                return [2 /*return*/];
                        }
                    });
                }); },
            },
        ];
        var assistantNav = [
            {
                icon: 'menu-chat.png',
                title: 'Home',
                url: '/assistant/home',
            },
            {
                icon: 'menu-profile.png',
                title: ' Profile Settings',
                url: '/assistant/profile',
            },
            {
                icon: 'menu-process-document.png',
                title: 'Reference Guide',
                url: '/assistant/reference',
            },
            {
                title: 'Meet with Assistant Success',
                url: 'assistant/calendar-book',
            },
            {
                title: "My To Do's",
                icon: 'menu-chat.png',
                url: this.platformUtilService.isDesktop() ? 'assistant/todos' : "/" + UI_ROUTES.monday_mobile,
            },
            {
                icon: 'menu-logout.png',
                title: 'Log Out',
                clickHandler: function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.authService.logout()];
                            case 1:
                                _a.sent();
                                if (this.mainMenu.isOpen) {
                                    this.mainMenu.close();
                                }
                                this.router.navigateByUrl('/login');
                                return [2 /*return*/];
                        }
                    });
                }); },
            },
        ];
        // console.log('Is assitant:');
        // console.log(this.isAssistant);
        this.navigate = this.isSuperAdmin ? superAdminNav : this.isAssistant ? assistantNav : clientNav;
    };
    AppComponent.prototype.insertProcessDocumentOption = function (nav) {
        var arr1 = nav.slice(0, 2);
        var arr2 = [
            {
                icon: 'menu-process-document.png',
                title: 'Process Document',
                url: '/process-doc',
            },
        ];
        var arr3 = nav.slice(2);
        return arr1.concat(arr2, arr3);
    };
    AppComponent.prototype.initializeInstabug = function () {
        window.InstabugLib.Instabug.start('4bfb5359a6a2c62df6f0982f42548311', function () {
            console.log('Instabug initialized.');
        }, function (error) {
            console.log('Instabug could not be initialized - ' + error);
        });
        window.InstabugLib.Instabug.setColorTheme('dark');
    };
    AppComponent.prototype.reqPermissionPN = function () {
        this.firebase.grantPermission().then(function (ok) {
            console.log('FIREBASE - grantPermission - OK: ', ok);
        }, function (err) {
            console.error('FIREBASE - grantPermission - ERR: ', err);
        });
    };
    AppComponent.prototype.initializePNListeners = function () {
        var _this = this;
        console.log('initializePNListeners ****');
        this.firebase.getToken().then(function (token) {
            console.log('FIREBASE - getToken:', token);
            _this.authService.setDeviceToken(token);
        });
        this.firebase.onTokenRefresh().subscribe(function (token) {
            console.log('FIREBASE - onTokenRefresh:', token);
            _this.authService.setDeviceToken(token);
        });
        this.firebase.onMessageReceived().subscribe(function (data) {
            console.log('FIREBASE - onMessageReceived - DATA: ', data);
            console.log('FIREBASE - onMessageReceived -  BADGE: ', _this.badge);
            // this.badge.increase(1);
            if (data.tap) {
                console.log('FIREBASE - tap: ' + data.tap);
                // this.badge.clear();
                if (data.roomId) {
                    if (_this.platformUtilService.isDesktop()) {
                        _this.router.navigateByUrl("/" + UI_ROUTES.chat + "/" + data.roomId);
                    }
                    else {
                        _this.router.navigateByUrl("/" + UI_ROUTES.chat_mobile + "/" + data.roomId);
                    }
                }
            }
            else {
                // console.log('Received in foreground ***');
            }
        });
    };
    AppComponent.prototype.createClientNav = function () {
        var _this = this;
        var paymentOptionUp = Number(this.userInfo.Onboarding)
            ? [
                {
                    icon: 'menu-chat.png',
                    title: 'Chat',
                    url: this.platformUtilService.isDesktop() ? "/" + UI_ROUTES.chat : "/" + UI_ROUTES.chat_mobile,
                },
                {
                    icon: 'menu-time-tracker.png',
                    title: 'Time Tracker',
                    url: '/time-tracker',
                },
            ]
            : [];
        paymentOptionUp.push({
            icon: 'menu-task-manager.png',
            title: 'Task Manager',
            url: this.platformUtilService.isDesktop() ? '/taskv2' : "/" + UI_ROUTES.taskv2_mobile,
        });
        var paymentOptionDown = Number(this.userInfo.Onboarding)
            ? [
                {
                    icon: 'menu-housekeeping.png',
                    title: 'Housekeeping',
                    url: '/housekeeping',
                },
            ]
            : [];
        return paymentOptionUp.concat([
            {
                icon: 'menu-payment.png',
                title: 'Payment',
                url: '/onboarding',
            },
            {
                icon: 'menu-schedule-call.png',
                title: 'Schedule a Call',
                url: '/schedule-call',
            },
            {
                icon: 'menu-profile.png',
                title: 'Profile Settings',
                url: '/profile',
            }
        ], paymentOptionDown, [
            {
                icon: 'menu-refer-a-friend-icon.svg',
                title: 'Refer a friend',
                clickHandler: function () {
                    if (_this.platform.is('cordova') && _this.platform.is('ios')) {
                        _this.inAppBrowser.create('https://chatterboss.getrewardful.com/signup', '_system');
                    }
                    else {
                        window.open('https://chatterboss.getrewardful.com/signup', '_blank');
                    }
                },
            },
            {
                icon: 'menu-logout.png',
                title: 'Log Out',
                clickHandler: function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.authService.logout()];
                            case 1:
                                _a.sent();
                                if (this.mainMenu.isOpen) {
                                    this.mainMenu.close();
                                }
                                this.router.navigateByUrl('/login');
                                localStorage.removeItem('customerID');
                                return [2 /*return*/];
                        }
                    });
                }); },
            },
        ]);
    };
    AppComponent.prototype.goTo = function (page) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (page.clickHandler) {
                    page.clickHandler();
                }
                else {
                    this.router.navigateByUrl(page.url);
                }
                return [2 /*return*/];
            });
        });
    };
    return AppComponent;
}());
export { AppComponent };
