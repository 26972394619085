import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { DEFAULT_ASSISTANT_EMAIL, SERVER_URL, STREAM_KEY } from '../../environments/environment';
import { DBService } from './db.service';
import { StreamChat } from 'stream-chat';
import { UI_ROUTES } from '../v2/constants/routes.constant';
import { PlatformUtilService } from '../v2/services/utils/platform.util.service';
import * as i0 from "@angular/core";
import * as i1 from "./db.service";
import * as i2 from "@angular/common/http";
import * as i3 from "@ionic/storage";
import * as i4 from "@angular/router";
import * as i5 from "@ionic/angular";
import * as i6 from "../v2/services/utils/platform.util.service";
var ChatService = /** @class */ (function () {
    function ChatService(dbService, httpClient, storage, router, platform, platformUtilService) {
        var _this = this;
        this.dbService = dbService;
        this.httpClient = httpClient;
        this.storage = storage;
        this.router = router;
        this.platform = platform;
        this.platformUtilService = platformUtilService;
        // API_KEY = "fyawmqrej5fv";
        this.API_KEY = STREAM_KEY;
        this.presenceStore = {};
        this.usersSubject = new BehaviorSubject([]);
        this.roomsSubject = new BehaviorSubject([]);
        this.streamChatUsers = [];
        this.channelsSubject = new BehaviorSubject([]);
        this.messagesSubject = new BehaviorSubject([]);
        this.assistantsSubject = new BehaviorSubject([]);
        this.activeUsersSubject = new BehaviorSubject([]);
        this.activeChannelsSubject = new BehaviorSubject([]);
        this.lastPageFromDbSubject = new BehaviorSubject([]);
        this.streamChatClient = null;
        this.streamChatConnection = null;
        this.currentUserId = '';
        this.lastPageCount = 0;
        this.onNewMessageChannelEventHandler = function (event) {
            // console.log('new Channel message event ***',event);
            var currentChannelsSet = _this.channelsSubject.getValue();
            var cid = event.cid;
            var matches = cid.match(/^messaging\:(.*)$/);
            var channelId = matches[1] ? matches[1] : '';
            var certainChannelIndex = currentChannelsSet.findIndex(function (channelData) { return channelData.id === channelId; });
            var certainChannel = currentChannelsSet[certainChannelIndex];
            if (!certainChannel) {
                var filter = {
                    type: 'messaging',
                    cid: { $in: [event.cid] },
                    members: { $in: [_this.streamChatClient.user.id] },
                };
                var sort = { last_message_at: -1 };
                _this.streamChatClient
                    .queryChannels(filter, sort, {
                    watch: true,
                    state: true,
                    limit: 1,
                })
                    .then(function (queryChannel) {
                    if (queryChannel.length > 0) {
                        var channel = queryChannel[0];
                        channel.queryMembers({}, { created_at: -1 }, {}).then(function (res) {
                            _this.addUsersIfNotExists(res.members);
                            _this.addChannelFromEvent(event.cid);
                        });
                    }
                });
                return;
            }
            certainChannel.unreadCount = certainChannel.countUnread();
            if (_this.isUserAssistant(event.user.id))
                certainChannel.unanswered = false;
            // console.log("certain channel ***");
            // console.log(certainChannel);
            var newChannelsSet = [
                certainChannel
            ].concat(currentChannelsSet.slice(0, certainChannelIndex), currentChannelsSet.slice(certainChannelIndex + 1));
            _this.channelsSubject.next(newChannelsSet);
        };
        this.onNewMessageClientEventHandler = function (event) {
            //console.log('new client message event ***',event);
            var currentChannelsSet = _this.channelsSubject.getValue();
            //console.log('current channels set ***');
            //console.log(currentChannelsSet);
            var cid = event.cid;
            var matches = cid.match(/^messaging\:(.*)$/);
            var channelId = matches[1] ? matches[1] : '';
            var certainChannelIndex = currentChannelsSet.findIndex(function (channelData) { return channelData.id === channelId; });
            var certainChannel = currentChannelsSet[certainChannelIndex];
            if (!certainChannel) {
                _this.addUsersIfNotExists(event.channel.members);
                _this.addChannelFromEvent(event.channel.cid);
                return;
            }
            certainChannel.unreadCount = certainChannel.countUnread();
            // console.log("certain channel ***");
            // console.log(certainChannel);
            // console.log('certain channel index ***');
            // console.log(certainChannelIndex);
            var newChannelsSet = [
                certainChannel
            ].concat(currentChannelsSet.slice(0, certainChannelIndex), currentChannelsSet.slice(certainChannelIndex + 1));
            // console.log('new channels set ***');
            // console.log(newChannelsSet);
            _this.channelsSubject.next(newChannelsSet);
        };
        this.setUpChatNotifications = this.setUpChatNotifications.bind(this);
    }
    ChatService.prototype.enableWebPush = function (emitter) {
        if (!('Notification' in window)) {
            //console.log("This browser does not support desktop notification");
            return;
        }
        if (Notification.permission === 'granted') {
            // If it's okay let's create a notification
            emitter();
            // this.setUpChatNotifications();
        }
        // Otherwise, we need to ask the user for permission
        else if (Notification.permission !== 'denied') {
            if (navigator.userAgent.search('Safari') >= 0 && navigator.userAgent.search('Chrome') < 0) {
                Notification.requestPermission(function (permission) {
                    if (permission === 'granted') {
                        emitter();
                        // this.setUpChatNotifications();
                    }
                });
            }
            else {
                Notification.requestPermission().then(function (permission) {
                    // If the user accepts, let's create a notification
                    if (permission === 'granted') {
                        emitter();
                        // this.setUpChatNotifications();
                    }
                });
            }
        }
    };
    ChatService.prototype.setUpChatNotifications = function () {
        var _this = this;
        this.streamChatClient.on('message.new', function (event) {
            var userName = event.user.name, messageText = event.message.text, cid = event.cid;
            var matches = cid.match(/^messaging\:(.*)$/);
            var channelId = matches[1] ? matches[1] : '';
            // to show notification only when tab is inactive
            if (document.hidden) {
                var notification = new Notification(userName, {
                    body: messageText,
                    data: {
                        channelId: channelId,
                    },
                    tag: channelId,
                });
                notification.onclick = function (event) {
                    var eventTarget = event.target && event.target;
                    var notificationData = eventTarget && eventTarget.data;
                    var channelId = notificationData ? notificationData.channelId : event.target.tag;
                    // const channelId = data.target && data.target.data && data.target.data.channelId ? data.target.data.channelId : null;
                    if (_this.platformUtilService.isDesktop()) {
                        _this.router.navigateByUrl("/" + UI_ROUTES.chat + "/" + channelId);
                    }
                    else {
                        _this.router.navigateByUrl("/" + UI_ROUTES.chat_mobile + "/" + channelId);
                    }
                    window.focus();
                };
            }
        });
    };
    // StreamChat connection
    ChatService.prototype.isConnectedToStreamChat = function () {
        return this.streamChatConnection !== null;
    };
    // obtine el access token y lo devuelve
    ChatService.prototype.getAccess = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var accessToken;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.storage.get('ACCESS_TOKEN')];
                    case 1:
                        accessToken = _a.sent();
                        return [2 /*return*/, accessToken];
                }
            });
        });
    };
    ChatService.prototype.connectToStreamChat = function (userId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var accessToken, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.storage.get('ACCESS_TOKEN')];
                    case 1:
                        accessToken = _b.sent();
                        if (!accessToken)
                            return [2 /*return*/, false];
                        this.streamChatClient = new StreamChat(this.API_KEY, {
                            timeout: 10000,
                        });
                        _a = this;
                        return [4 /*yield*/, this.streamChatClient.setUser({
                                id: userId,
                            }, accessToken)];
                    case 2:
                        _a.streamChatConnection = _b.sent();
                        this.currentUserId = userId;
                        // check if app is running in desktop browser
                        if (!this.platform.is('hybrid')) {
                            // ask permission and subscribe to event
                            this.enableWebPush(this.setUpChatNotifications);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ChatService.prototype.disconnectFromStreamChat = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.streamChatClient.disconnect()];
                    case 1:
                        _a.sent();
                        this.streamChatClient = null;
                        this.streamChatConnection = null;
                        this.usersSubject.next([]);
                        this.channelsSubject.next([]);
                        return [2 /*return*/];
                }
            });
        });
    };
    // Get data from Users
    ChatService.prototype.fetchAssistants = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dbService.getUsersAssistants().then(function (assistants) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                this.assistantsSubject.next(assistants);
                                return [2 /*return*/];
                            });
                        }); })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ChatService.prototype.fetchAndMergeUsersDataAll = function () {
        var _this = this;
        this.dbService.getAllUsers().subscribe(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var usersFromDB, mergedUsersData, userIds, restOfUsers, limitForQuery, i, part;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        usersFromDB = res;
                        mergedUsersData = [];
                        userIds = usersFromDB.map(function (userData) { return userData.id; });
                        restOfUsers = [];
                        limitForQuery = 50;
                        i = 0;
                        _a.label = 1;
                    case 1:
                        if (!(i < userIds.length)) return [3 /*break*/, 4];
                        part = userIds.slice(i, i + limitForQuery);
                        return [4 /*yield*/, this.streamChatClient.queryUsers({
                                id: { $in: part },
                            }, { last_active: -1 }, {
                                presence: false,
                                limit: limitForQuery,
                            })];
                    case 2:
                        restOfUsers = _a.sent();
                        this.streamChatUsers = this.streamChatUsers.concat(restOfUsers.users);
                        _a.label = 3;
                    case 3:
                        i += limitForQuery;
                        return [3 /*break*/, 1];
                    case 4:
                        // TODO: streamChat returns only 100 users, but there are more. resolve it later. Maybe avatars will not be shown due to this
                        usersFromDB.forEach(function (dbUserData) {
                            var streamChatUserData = _this.streamChatUsers.find(function (streamChatUserData) { return streamChatUserData.id === dbUserData.id; });
                            // when added this users without avatars dissapeared but a lot of users (who was without avatar dissapeared at all)
                            if (!streamChatUserData)
                                return;
                            var mergedUserData = tslib_1.__assign({}, dbUserData, streamChatUserData);
                            mergedUsersData.push(mergedUserData);
                            // console.log('dbUserData: ***', dbUserData);
                        });
                        this.usersSubject.next(mergedUsersData);
                        return [2 /*return*/];
                }
            });
        }); });
    };
    ChatService.prototype.fetchAndMergeUsersData = function (active, page) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(active == 'true')) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.dbService.getUsers(active, page).then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                return tslib_1.__generator(this, function (_a) {
                                    if (res.users.length <= 0)
                                        return [2 /*return*/];
                                    console.log('Active users:', res.users);
                                    this.lastPageFromDbSubject.next(res);
                                    this.totalItems = res.totalItems;
                                    this.fetchAndMergeUsersDataFromUsers(res.users, active);
                                    return [2 /*return*/];
                                });
                            }); })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.dbService.getUsers(active, page).then(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                if (res.users.length <= 0)
                                    return [2 /*return*/];
                                console.log("Users page " + page, res.users);
                                console.log("Total items: " + res.totalItems);
                                this.lastPageFromDbSubject.next(res);
                                this.fetchAndMergeUsersDataFromUsers(res.users, active);
                                return [2 /*return*/];
                            });
                        }); })];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    // async updateLastActivity() {
    //   this.dbService.getAllUsers()
    //     .subscribe( async(resp: any[]) => {
    //       const userIds = resp.map((userData) => userData.id).slice(1600);
    //       console.log( userIds.length );
    //       const limit = 30;
    //       let offset = 0;
    //       for (let index = 1; index <= userIds.length; index++) {
    //         const  part = userIds.slice(offset, limit * index);
    //         if(part.length === 0){
    //           continue;
    //         }
    //         const respQUser = await this.streamChatClient.queryUsers(
    //           {id: { $in: part } },
    //           { last_active: -1 },
    //           {});
    //         console.log( "la buena respuesta :",respQUser );
    //         respQUser.users.forEach(
    //           user => this.dbService.updateUserData( user.id, { lastActivity: user.last_active })
    //           .then( res => res.subscribe( console.log ) )
    //         )
    //         offset += limit;
    //       }
    //     });
    // }
    ChatService.prototype.fetchAndMergeUsersDataFromUsers = function (users, active) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var usersFromDB, mergedUsersData, userIds, restOfUsers, limitForQuery, i, part, all, all;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        usersFromDB = users;
                        mergedUsersData = [];
                        userIds = usersFromDB.map(function (userData) { return userData.id; });
                        this.streamChatUsers = [];
                        restOfUsers = [];
                        limitForQuery = 50;
                        i = 0;
                        _a.label = 1;
                    case 1:
                        if (!(i < userIds.length)) return [3 /*break*/, 4];
                        part = userIds.slice(i, i + limitForQuery);
                        return [4 /*yield*/, this.streamChatClient.queryUsers({
                                id: { $in: part },
                            }, { last_active: -1 }, {
                                presence: false,
                                limit: limitForQuery,
                            })];
                    case 2:
                        restOfUsers = _a.sent();
                        this.streamChatUsers = this.streamChatUsers.concat(restOfUsers.users);
                        _a.label = 3;
                    case 3:
                        i += limitForQuery;
                        return [3 /*break*/, 1];
                    case 4:
                        // TODO: streamChat returns only 100 users, but there are more. resolve it later. Maybe avatars will not be shown due to this
                        usersFromDB.forEach(function (dbUserData) {
                            var streamChatUserData = _this.streamChatUsers.find(function (streamChatUserData) { return streamChatUserData.id === dbUserData.id; });
                            // when added this users without avatars dissapeared but a lot of users (who was without avatar dissapeared at all)
                            if (!streamChatUserData)
                                return;
                            var mergedUserData = tslib_1.__assign({}, dbUserData, streamChatUserData);
                            mergedUsersData.push(mergedUserData);
                            // console.log('dbUserData: ***', dbUserData);
                        });
                        if (active == 'true') {
                            this.activeUsersSubject.next(mergedUsersData);
                            all = this.assistantsSubject.getValue().concat(mergedUsersData);
                            this.usersSubject.next(all);
                        }
                        else {
                            all = this.assistantsSubject.getValue().concat(this.activeUsersSubject.getValue(), mergedUsersData);
                            this.usersSubject.next(all);
                        }
                        console.log('merged users data ***', this.usersSubject.getValue());
                        return [2 /*return*/];
                }
            });
        });
    };
    ChatService.prototype.searchUsers = function (value) {
        var _this = this;
        return new Observable(function (observer) {
            return _this.dbService.searchUsers(value).subscribe(function (users) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    this.fetchAndMergeUsersDataFromUsers(users, 'true');
                    this.fetchStreamChatChannelsFromUsers(users, 'true');
                    this.removeOnNewMessageHandler();
                    observer.next(true);
                    return [2 /*return*/];
                });
            }); });
        });
    };
    ChatService.prototype.updateAndMergeUsersData = function (ids) {
        var _this = this;
        console.warn(ids);
        return this.dbService.getUsersById(ids).pipe(flatMap(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var usersFromDB, mergedUsersData, userIds, streamChatUsers, restOfUsers, limitForQuery, offsetForQuery;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        usersFromDB = JSON.parse(res.body);
                        mergedUsersData = [];
                        userIds = usersFromDB.map(function (userData) { return userData.id; });
                        streamChatUsers = [];
                        restOfUsers = [];
                        limitForQuery = 100;
                        offsetForQuery = 0;
                        _a.label = 1;
                    case 1: return [4 /*yield*/, this.streamChatClient.queryUsers({ id: { $in: userIds } }, { last_active: -1 }, {
                            presence: false,
                            limit: limitForQuery,
                            offset: offsetForQuery,
                        })];
                    case 2:
                        restOfUsers = _a.sent();
                        streamChatUsers = streamChatUsers.concat(restOfUsers.users);
                        offsetForQuery = offsetForQuery + restOfUsers.users.length; // this offset is calcaulated properly
                        _a.label = 3;
                    case 3:
                        if (restOfUsers.users.length >= limitForQuery) return [3 /*break*/, 1];
                        _a.label = 4;
                    case 4:
                        usersFromDB.forEach(function (dbUserData) {
                            var streamChatUserData = streamChatUsers.find(function (streamChatUserData) { return streamChatUserData.id === dbUserData.id; });
                            // when added this users without avatars dissapeared but a lot of users (who was without avatar dissapeared at all)
                            if (!streamChatUserData)
                                return;
                            var mergedUserData = tslib_1.__assign({}, dbUserData, streamChatUserData);
                            mergedUsersData.push(mergedUserData);
                        });
                        // console.log("mergedUsersData ",mergedUsersData);
                        return [2 /*return*/, mergedUsersData];
                }
            });
        }); }));
    };
    ChatService.prototype.fetchAndMergeUsersDataAssistants = function (active) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var userIds;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                userIds = this.usersSubject.getValue().map(function (userData) { return userData.id; });
                if (userIds.length <= 0)
                    return [2 /*return*/];
                this.dbService.getUsersById(userIds).subscribe(function (res) {
                    if (res.status === 200) {
                        var data = JSON.parse(res.body);
                        var mergedUsersData_1 = [];
                        data.forEach(function (userDataFromDB) {
                            // console.log("user data from db  $$ ***");
                            // console.log(userDataFromDB);
                            var userDataFromChat = _this.usersSubject
                                .getValue()
                                .find(function (chatServiceData) { return chatServiceData.id === userDataFromDB.id; });
                            // console.log("user data from chat $$ ***");
                            // console.log(userDataFromChat);
                            if (userDataFromChat) {
                                mergedUsersData_1.push(tslib_1.__assign({}, userDataFromDB, userDataFromChat));
                            }
                        });
                        if (active) {
                            _this.activeUsersSubject.next(mergedUsersData_1);
                            _this.usersSubject.next(mergedUsersData_1);
                        }
                        else {
                            var all = _this.activeUsersSubject.getValue().concat(mergedUsersData_1);
                            _this.usersSubject.next(all);
                        }
                        console.log('usersSubject', _this.usersSubject.getValue());
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    // Get data from Channels for Super Assistant
    ChatService.prototype.fetchStreamChatChannels = function (active) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        res = this.lastPageFromDbSubject.getValue();
                        return [4 /*yield*/, this.fetchStreamChatChannelsFromUsers(res.users, active, res.totalItems)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ChatService.prototype.fetchStreamChatChannelsFromUsers = function (users, active, totalItems) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var channels2, response, all;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        channels2 = [];
                        if (!users)
                            return [2 /*return*/];
                        if (users.length <= 0)
                            return [2 /*return*/];
                        return [4 /*yield*/, this.getStreamChatClient(users)];
                    case 1:
                        response = _a.sent();
                        channels2 = response;
                        console.log('respuesta active: ', active);
                        if (active == 'true') {
                            this.activeChannelsSubject.next(channels2);
                            this.channelsSubject.next(channels2);
                        }
                        else {
                            all = this.activeChannelsSubject.getValue().concat(channels2);
                            this.channelsSubject.next(all);
                            if (totalItems)
                                this.totalItems = totalItems;
                        }
                        console.log('channels in chat service after we got them ***', this.channelsSubject.getValue());
                        return [2 /*return*/];
                }
            });
        });
    };
    ChatService.prototype.getStreamChatClient = function (users) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var limit, userIds, index, filter, sort, channels, index_1, restOfChannels;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        limit = 30;
                        userIds = users.map(function (userData) { return userData.id; });
                        index = userIds.indexOf(this.streamChatClient.user.id);
                        if (index > -1)
                            userIds.splice(index, 1);
                        filter = {
                            type: 'messaging',
                            members: { $in: [this.streamChatClient.user.id] } && { $in: userIds },
                        };
                        sort = { last_message_at: -1 };
                        channels = [];
                        index_1 = 0;
                        _a.label = 1;
                    case 1:
                        if (!(index_1 < userIds.length)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.streamChatClient.queryChannels(filter, sort, {
                                watch: true,
                                state: true,
                                limit: limit,
                                offset: index_1,
                            })];
                    case 2:
                        restOfChannels = _a.sent();
                        channels = channels.concat(restOfChannels);
                        _a.label = 3;
                    case 3:
                        index_1 += limit;
                        return [3 /*break*/, 1];
                    case 4:
                        console.log('rest of chanel: ', channels);
                        channels.map(function (channel) { return ((channel.unreadCount = channel.countUnread()), (channel.unanswered = false)); });
                        return [2 /*return*/, channels];
                }
            });
        });
    };
    // Get data from Channels for Regular Assistants
    ChatService.prototype.fetchStreamChatChannelsAssistant = function (active) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var channels, restOfChannels, limitForQuery, offsetForQuery, assistants, dateLimit, filter, sort, e_1, channelsAux, allMembers, allUsers, allChannels;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        channels = [];
                        restOfChannels = [];
                        limitForQuery = 30;
                        offsetForQuery = 0;
                        assistants = this.assistantsSubject.getValue();
                        dateLimit = new Date();
                        dateLimit.setHours(0);
                        dateLimit.setMinutes(0);
                        dateLimit.setSeconds(0);
                        dateLimit.setDate(dateLimit.getDate() - 5);
                        filter = {
                            type: 'messaging',
                            members: { $in: [this.streamChatClient.user.id] },
                        };
                        sort = { last_message_at: -1 };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.streamChatClient.queryChannels(filter, sort, {
                                watch: true,
                                state: true,
                                limit: limitForQuery,
                                offset: offsetForQuery,
                            })];
                    case 2:
                        restOfChannels = _a.sent();
                        restOfChannels.map(function (channel) { return ((channel.unreadCount = channel.countUnread()), (channel.unanswered = false)); });
                        channels = channels.concat(restOfChannels);
                        offsetForQuery = offsetForQuery + restOfChannels.length;
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        console.error(e_1);
                        return [3 /*break*/, 4];
                    case 4:
                        if (restOfChannels.length >= limitForQuery) return [3 /*break*/, 1];
                        _a.label = 5;
                    case 5:
                        channelsAux = [];
                        channels.forEach(function (channel, index) {
                            var isActive = channel.state.last_message_at >= dateLimit;
                            if (active == isActive)
                                channelsAux.push(channel);
                        });
                        channels = channelsAux;
                        allMembers = [];
                        channels.forEach(function (channel, index) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var roomUsers;
                            return tslib_1.__generator(this, function (_a) {
                                roomUsers = channel.state.members.asMutable();
                                Object.keys(roomUsers).forEach(function (userId) {
                                    var userDataFromChatService = roomUsers[userId].user;
                                    var assistant = assistants.find(function (assistantData) { return assistantData.id === userDataFromChatService.id; });
                                    if (!assistant)
                                        allMembers.push(userDataFromChatService);
                                });
                                return [2 /*return*/];
                            });
                        }); });
                        // console.log("allMembers",allMembers);
                        if (active) {
                            this.activeUsersSubject.next(allMembers);
                            this.usersSubject.next(allMembers);
                            this.activeChannelsSubject.next(channels);
                            this.channelsSubject.next(channels);
                        }
                        else {
                            allUsers = this.activeUsersSubject.getValue().concat(allMembers);
                            this.usersSubject.next(allUsers);
                            allChannels = this.activeChannelsSubject.getValue().concat(channels);
                            this.channelsSubject.next(allChannels);
                        }
                        console.log('channelsSubject', this.channelsSubject.getValue());
                        return [2 /*return*/];
                }
            });
        });
    };
    // Events
    ChatService.prototype.attachChannelsUpdateOnNewMessageChannel = function () {
        this.streamChatClient.on('message.new', this.onNewMessageChannelEventHandler);
    };
    ChatService.prototype.attachChannelsUpdateOnNewMessage = function () {
        this.streamChatClient.on('notification.message_new', this.onNewMessageClientEventHandler);
    };
    ChatService.prototype.removeOnNewMessageHandler = function () {
        this.streamChatClient.off('message.new', this.onNewMessageChannelEventHandler);
        this.streamChatClient.off('notification.message_new', this.onNewMessageClientEventHandler);
    };
    ChatService.prototype.addChannelFromEvent = function (channelCid) {
        var _this = this;
        var currentChannelsSet = this.channelsSubject.getValue();
        var filter = {
            type: 'messaging',
            cid: { $in: [channelCid] },
        };
        var sort = { last_message_at: -1 };
        this.streamChatClient
            .queryChannels(filter, sort, {
            watch: true,
            state: true,
            limit: 1,
        })
            .then(function (queryChannel) {
            // console.log("Current channels",currentChannelsSet);
            // console.log("Channel not loaded",event.channel);
            if (queryChannel.length > 0) {
                var newChannel = queryChannel[0];
                // console.log("New channel",newChannel);
                newChannel = Object.assign(newChannel, { unreadCount: queryChannel[0].countUnread(), unanswered: false });
                // console.log("New channel",newChannel);
                var newChannelsSet = [newChannel].concat(currentChannelsSet);
                _this.channelsSubject.next(newChannelsSet);
                // console.log("Channel added", this.channelsSubject.getValue());
                var newActiveChannels = [newChannel].concat(_this.activeChannelsSubject.getValue());
                _this.activeChannelsSubject.next(newActiveChannels);
            }
        });
    };
    ChatService.prototype.addUsersIfNotExists = function (members) {
        var _this = this;
        if (!members)
            return;
        var userIds = members.map(function (userData) { return userData.user_id; });
        var currentUserIds = this.usersSubject.getValue().map(function (userData) { return userData.id; });
        var missingUserIds = userIds.filter(function (x) { return !currentUserIds.includes(x); });
        // console.log("missingUserIds ",missingUserIds);
        if (missingUserIds.length <= 0)
            return;
        this.updateAndMergeUsersData(missingUserIds).subscribe(function (mergedUsersData) {
            var currentUsersSet = _this.usersSubject.getValue();
            var all = currentUsersSet.concat(mergedUsersData);
            // console.log("Current users:",this.usersSubject.getValue());
            // console.log("Missing users:",missingUserIds);
            _this.usersSubject.next(all);
            // console.log("Users added:",this.usersSubject.getValue());
            var newActiveUsers = _this.activeUsersSubject.getValue().concat(mergedUsersData);
            _this.activeUsersSubject.next(newActiveUsers);
        });
    };
    ChatService.prototype.updateChannelInSubject = function (cid) {
        var currentChannelsSet = this.channelsSubject.getValue();
        var matches = cid.match(/^messaging\:(.*)$/);
        var channelId = matches[1] ? matches[1] : '';
        var certainChannelIndex = currentChannelsSet.findIndex(function (channelData) { return channelData.id === channelId; });
        var certainChannel = currentChannelsSet[certainChannelIndex];
        certainChannel.unreadCount = certainChannel.countUnread();
        this.channelsSubject.next(currentChannelsSet);
    };
    ChatService.prototype.attachChannelsUpdateOnMarkRead = function () {
        var _this = this;
        this.streamChatClient.on('message.read', function (event) {
            var isCurrentUserRead = event.user.id === _this.currentUserId;
            if (!isCurrentUserRead)
                return;
            var currentChannelsSet = _this.channelsSubject.getValue();
            var cid = event.cid;
            var matches = cid.match(/^messaging\:(.*)$/);
            var channelId = matches[1] ? matches[1] : '';
            var certainChannelIndex = currentChannelsSet.findIndex(function (channelData) { return channelData.id === channelId; });
            var certainChannel = currentChannelsSet[certainChannelIndex];
            certainChannel.unreadCount = certainChannel.countUnread();
            _this.channelsSubject.next(currentChannelsSet);
            // //console.log('read message event for client was caught ***');
            // //console.log(event);
            // // //console.log(this.currentChannel.state.read);
            // //console.log('*********************************');
        });
    };
    ChatService.prototype.attachChannelsUpdateOnAddedToChannel = function () {
        var _this = this;
        this.streamChatClient.on('notification.added_to_channel', function (event) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var 
            // property id without prefix "messaging" exists there in correct format
            channel, filter, sort, channels, channelInstance, channelLastMessageAt, channelState, currentChannelsSet, firstLastMessageLaterMatch, newChannelsSet, date, created_at, userName, messageText, channelId, notification, channelMembers, usersInStore, usersDoesntExistsInStore;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        channel = event.channel;
                        filter = { type: 'messaging', id: channel.id };
                        sort = { last_message_at: -1 };
                        return [4 /*yield*/, this.streamChatClient.queryChannels(filter, sort, {
                                watch: true,
                                state: true,
                            })];
                    case 1:
                        channels = _a.sent();
                        channelInstance = channels[0];
                        channelLastMessageAt = channelInstance.state.last_message_at;
                        return [4 /*yield*/, channelInstance.watch()];
                    case 2:
                        channelState = _a.sent();
                        currentChannelsSet = this.channelsSubject.getValue();
                        firstLastMessageLaterMatch = currentChannelsSet.findIndex(function (channel) {
                            return channel.state.last_message_at > channelLastMessageAt;
                        });
                        if (firstLastMessageLaterMatch === -1 || firstLastMessageLaterMatch === 0) {
                            newChannelsSet = [channelInstance].concat(currentChannelsSet);
                        }
                        else {
                            newChannelsSet = currentChannelsSet.slice(0, firstLastMessageLaterMatch).concat([
                                channelInstance
                            ], currentChannelsSet.slice(firstLastMessageLaterMatch + 1));
                        }
                        date = new Date();
                        date.setMinutes(date.getMinutes() - 5);
                        created_at = new Date(channel.created_at);
                        if (created_at > date) {
                            userName = channel.name;
                            messageText = "A new client " + userName + " just registered";
                            channelId = channel.id;
                            notification = new Notification('ChatterBoss', {
                                body: messageText,
                                data: {
                                    channelId: channelId,
                                },
                                tag: channelId,
                            });
                            notification.onclick = function (event) {
                                var eventTarget = event.target && event.target;
                                var notificationData = eventTarget && eventTarget.data;
                                var channelId = notificationData ? notificationData.channelId : event.target.tag;
                                if (_this.platformUtilService.isDesktop()) {
                                    _this.router.navigateByUrl("/" + UI_ROUTES.chat + "/" + channelId);
                                }
                                else {
                                    _this.router.navigateByUrl("/" + UI_ROUTES.chat_mobile + "/" + channelId);
                                }
                                window.focus();
                            };
                        }
                        channelMembers = channelInstance.state.members.asMutable();
                        usersInStore = this.usersSubject.getValue();
                        usersDoesntExistsInStore = [];
                        Object.keys(channelMembers).forEach(function (userId) {
                            var instanceInUsersList = usersInStore.find(function (userData) { return userData.id === userId; });
                            if (!instanceInUsersList)
                                usersDoesntExistsInStore.push(userId);
                        });
                        if (!usersDoesntExistsInStore.length) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.addUsersDataToStore(usersDoesntExistsInStore)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        // //console.log('users doesnt exists in store ***');
                        // //console.log(usersDoesntExistsInStore);
                        // //console.log('********************************');
                        // channelMembers.forEach(userData => {
                        //   //console.log('user data ***');
                        //   //console.log(userData);
                        // });
                        // \ fetch new client from db and add to usersList
                        this.channelsSubject.next(newChannelsSet);
                        return [2 /*return*/];
                }
            });
        }); });
    };
    ChatService.prototype.addUsersDataToStore = function (userIds) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var usersDataFromStore, chatServiceData, chatServiceUsers;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        usersDataFromStore = this.usersSubject.getValue();
                        return [4 /*yield*/, this.streamChatClient.queryUsers({ id: { $in: userIds } }, { last_active: -1 }, {
                                presence: false,
                            })];
                    case 1:
                        chatServiceData = _a.sent();
                        chatServiceUsers = chatServiceData.users;
                        //console.log("chat service users data: $ ***");
                        //console.log(chatServiceUsers);
                        this.dbService.getUsersById(userIds).subscribe(function (res) {
                            //console.log("response on getting users from chat service: $ ***");
                            //console.log(res);
                            if (res.status === 200) {
                                var data = JSON.parse(res.body);
                                var usersToAdd_1 = [];
                                data.forEach(function (userDataFromDB) {
                                    //console.log("user data from db  $$ ***");
                                    //console.log(userDataFromDB);
                                    var userDataFromChat = chatServiceUsers.find(function (chatServiceData) { return chatServiceData.id === userDataFromDB.id; });
                                    //console.log("user data from chat $$ ***");
                                    //console.log(userDataFromChat);
                                    if (userDataFromChat) {
                                        usersToAdd_1.push(tslib_1.__assign({}, userDataFromDB, userDataFromChat));
                                    }
                                });
                                //console.log("users to add $$ ***");
                                //console.log(usersToAdd);
                                if (usersToAdd_1.length) {
                                    //console.log("make action $$ ***");
                                    //console.log(usersToAdd);
                                    _this.usersSubject.next(usersDataFromStore.concat(usersToAdd_1));
                                }
                            }
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    // Other functions
    ChatService.prototype.isUserOnline = function (userId) {
        if (Object.keys(this.presenceStore).length) {
            return this.presenceStore[userId] ? this.presenceStore[userId] === 'online' : false;
        }
    };
    ChatService.prototype.isSuper = function (userId) {
        var user = this.assistantsSubject.getValue().find(function (userData) { return userData.id === userId; });
        if (!user) {
            return false;
        }
        return user.email === DEFAULT_ASSISTANT_EMAIL ? true : false;
    };
    ChatService.prototype.isUserAssistant = function (userId) {
        var user = this.assistantsSubject.getValue().find(function (userData) { return userData.id === userId; });
        return user ? true : false;
    };
    ChatService.prototype.getUsers = function () {
        return this.usersSubject;
    };
    ChatService.prototype.getMessages = function () {
        return this.messagesSubject;
    };
    ChatService.prototype.channelsWithUserData$ = function () {
        var _this = this;
        return combineLatest(this.usersSubject, this.channelsSubject).pipe(map(function (_a) {
            // console.log("some update in channelsWithUserData$ observable: ***");
            // console.log(usersList);
            // console.log(roomsList);
            // console.log("************************");
            var usersList = _a[0], roomsList = _a[1];
            // console.log('users list inside complex observable ***');
            // console.log(usersList);
            // console.log('rooms list inside complex observable ***');
            // console.log(roomsList);
            // TODO: replace line below with RXJS operator
            if (!usersList.length || !roomsList.length)
                return [];
            var roomsToReturn = [];
            // note: I got undefined but when add logs below it started to work
            // console.log('users list ***');
            // console.log(usersList);
            roomsList.forEach(function (roomData, index) {
                var client;
                var assistants = [];
                // console.log("Room ",roomData );
                // TODO: provide more proper way
                // const unreadCount = roomData.countUnread();
                // console.log('unread count ***');
                // console.log(unreadCount);
                var roomUsers = roomData.state.members.asMutable();
                // console.log("channel users in observable when run through channels: ***");
                // console.log(roomUsers);
                var userDoesntExists = false;
                Object.keys(roomUsers).forEach(function (userId) {
                    // console.log("user id we are trying to request: ***");
                    // console.log(userId);
                    var userDataFromChatService = roomUsers[userId];
                    // console.log("user data from chat service: ***");
                    // console.log(userDataFromChatService);
                    var fullUserData = usersList.find(function (userData) { return userData.id === userId; });
                    // console.log('full user data: ***');
                    // console.log(fullUserData);
                    // console.log('***');
                    // needed because it's possible case channel is created on user registered but client is not in users list
                    if (!fullUserData) {
                        userDoesntExists = true;
                        return;
                    }
                    // console.log("full user data in observable when run through channel users: ***");
                    // console.log(fullUserData);
                    // console.log('full user data ***');
                    // console.log(fullUserData);
                    if (fullUserData.isAssistant) {
                        if (fullUserData.id !== _this.currentUserId && fullUserData.email !== DEFAULT_ASSISTANT_EMAIL) {
                            assistants.push(fullUserData);
                        }
                    }
                    else {
                        client = fullUserData;
                    }
                });
                // console.log("client in observable $$$ ***");
                // console.log(client);
                var dataToPush = {
                    channelData: roomData,
                    roomAssistants: assistants,
                    roomClient: client,
                };
                // console.log("data to push in observable $$$ ***");
                // console.log(dataToPush);
                // miss the room if client doesn't exists there
                if (!client)
                    return;
                roomsToReturn.push(dataToPush);
            });
            console.log('rooms to return in observable $$$ ***', roomsToReturn);
            return roomsToReturn;
        }));
    };
    // User db functions
    ChatService.prototype.updateUserData = function (userId, userData) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dbService.updateUserData(userId, userData)];
                    case 1:
                        (_a.sent()).subscribe(function (res) {
                            var users = _this.usersSubject.getValue();
                            var requestedUserId = users.findIndex(function (userData) {
                                return userData.id === userId;
                            });
                            _this.usersSubject.next(users.slice(0, requestedUserId).concat([
                                tslib_1.__assign({}, users[requestedUserId], userData)
                            ], users.slice(requestedUserId + 1)));
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    ChatService.prototype.deleteUser = function (userId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dbService.deleteUser(userId)];
                    case 1:
                        (_a.sent()).subscribe(function (res) {
                            if (res.user && res.user.id) {
                                //console.log(`user ${res.user.id} was deleted`);
                                _this.removeUserFromSubject(res.user.id);
                            }
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    // Users chat functions
    ChatService.prototype.blockUser = function (userId, whoMakeAction) {
        return this.httpClient.post(SERVER_URL + "/banUser", {
            userId: userId,
            whoMakeAction: whoMakeAction,
        });
    };
    ChatService.prototype.unblockUser = function (userId, whoMakeAction) {
        return this.httpClient.post(SERVER_URL + "/unbanUser", {
            userId: userId,
            whoMakeAction: whoMakeAction,
        });
    };
    ChatService.prototype.updateStreamChatUserDataInSubject = function (userId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var updatedStreamChatData, updatedStreamChatUser, usersDataFromStore, certainUserIndex;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.streamChatClient.queryUsers({ id: { $in: [userId] } }, { last_active: -1 }, {
                            presence: false,
                            limit: 1,
                            offset: 0,
                        })];
                    case 1:
                        updatedStreamChatData = _a.sent();
                        updatedStreamChatUser = updatedStreamChatData.users[0];
                        usersDataFromStore = this.usersSubject.getValue();
                        certainUserIndex = usersDataFromStore.findIndex(function (userData) { return userData.id === userId; });
                        if (certainUserIndex === -1)
                            return [2 /*return*/];
                        usersDataFromStore[certainUserIndex] = tslib_1.__assign({}, usersDataFromStore[certainUserIndex], updatedStreamChatUser);
                        this.usersSubject.next(usersDataFromStore);
                        return [2 /*return*/];
                }
            });
        });
    };
    ChatService.prototype.removeUserFromSubject = function (userId) {
        var currentUsersSet = this.usersSubject.getValue();
        var userIndex = currentUsersSet.findIndex(function (userData) { return userData.id === userId; });
        if (userIndex === -1)
            return;
        var newUsersSet = currentUsersSet.slice(0, userIndex).concat(currentUsersSet.slice(userIndex + 1));
        this.usersSubject.next(newUsersSet);
    };
    ChatService.prototype.removeChannelFromSubject = function (channelId) {
        var currentChannelsSet = this.channelsSubject.getValue();
        var channelIndex = currentChannelsSet.findIndex(function (channelData) { return channelData.id === channelId; });
        if (channelIndex === -1)
            return;
        var newChannelsSet = currentChannelsSet.slice(0, channelIndex).concat(currentChannelsSet.slice(channelIndex + 1));
        this.channelsSubject.next(newChannelsSet);
    };
    // Get data to using in Modals
    ChatService.prototype.assistantsAvailableToSubscribeInRoom$ = function (channelId) {
        // //console.log('id in observable: ***');
        // //console.log(channelId);
        var _this = this;
        // //console.log('current user id: ***');
        // //console.log(this.currentUserId);
        var channel = this.channelsSubject.getValue().find(function (channelData) { return channelData.id === channelId; });
        if (channel) {
            var channelMembers = channel.state.members;
            var memberIds_1 = Object.keys(channelMembers);
            // //console.log('channel members: ***');
            // //console.log(channelMembers);
            return this.usersSubject.pipe(map(function (users) {
                return users.filter(function (userData) {
                    return userData.isAssistant && _this.currentUserId !== userData.id && !memberIds_1.includes(userData.id);
                });
            }));
        }
        else {
            return this.usersSubject;
        }
        // //console.log('channel: ***');
        // //console.log(channel);
    };
    ChatService.prototype.assistantsAvailableToRemoveFromRoom$ = function (channelId) {
        var _this = this;
        var channel = this.channelsSubject.getValue().find(function (channelData) { return channelData.id === channelId; });
        if (channel) {
            var channelMembers = channel.state.members;
            var memberIds_2 = Object.keys(channelMembers);
            // //console.log('channel members: ***');
            // //console.log(channelMembers);
            return this.usersSubject.pipe(map(function (users) {
                return users.filter(function (userData) {
                    return userData.isAssistant && _this.currentUserId !== userData.id && memberIds_2.includes(userData.id);
                });
            }));
        }
        else {
            return this.usersSubject;
        }
    };
    ChatService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ChatService_Factory() { return new ChatService(i0.ɵɵinject(i1.DBService), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.Storage), i0.ɵɵinject(i4.Router), i0.ɵɵinject(i5.Platform), i0.ɵɵinject(i6.PlatformUtilService)); }, token: ChatService, providedIn: "root" });
    return ChatService;
}());
export { ChatService };
