<ion-app>
  <ion-menu
    #mainMenu
    *ngIf="isAuth"
    side="start"
    menuId="first"
    contentId="content1"
    type="push"
  >
    <ion-content>
      <div class="container">
        <img
          class="menu-back show-hand"
          src="assets/icon/menu-back.png"
          (click)="mainMenu.close()"
        />

        <div class="user-info" *ngIf="userInfo && userInfo.streamChatData">
          <div class="avatar-c">
            <img
              [src]="userInfo.streamChatData.image"
              onerror="this.src='../assets/icon/avatar.png'"
            />
          </div>
          <div class="name">{{ userInfo.name }}</div>
        </div>

        <div class="divider"></div>

        <div
          class="item-container show-hand"
          *ngFor="let page of navigate; let i = index"
          (click)="mainMenu.close() && goTo(page)"
          [style.marginBottom]="i + 2 < navigate.length ? '30px' : '50px'"
        >
          <img
            [src]="'assets/icon/' + page.icon"
            onerror="this.src='../assets/icon/menu-profile.png'"
          />
          <p>{{ page.title }}</p>
        </div>
      </div>
    </ion-content>
  </ion-menu>

  <ion-router-outlet class="mainRouterOutlet" id="content1"></ion-router-outlet>
</ion-app>
