import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class PlatformUtilService {

  constructor(private platform: Platform) { }

  isDesktop() {
    return !this.isMobile();
  }

  isMobile() {
    return this.platform.is('cordova');
  }

  isIOS() {
    return this.platform.is('ios');
  }

}
